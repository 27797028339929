export const initialState = {
    projects: [],
    project: {
        archived: false,
        accesscontrols: [],
        basecurrency: null,
        cashaccounts: [],
        clients: [],
        currencies: [],
        exchangerates: [],
        expenseaccounts: [],
        expensegroups: [],
        incomeaccounts: [],
        incomegroups: [],
        interaccounttransfers: [],
        inventory: [],
        journalentries: [],
        name: '',
        lastmodified: 0,
        projectdetails: null,
        projectdetailsimage: null,
        projectid: null,
        projectuserid: null,
        purchaseinvoices: [],
        reconciliations: [],
        quotations: [],
        salesinvoices: [],
        size: '',
        startdate: '',
        subaccounts: [],
        suppliers: [],
        transactions: [],
        users: []
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_PROJECTS":
            return {
                ...state,
                mode: action.mode,
                projects: action.projects
            }
        case "SET_PROJECT":
            return {
                ...state,
                mode: action.mode,
                project: action.project
            }
        case "ADD_PROJECT":
            return {
                ...state,
                projects: [...state.projects, action.project],
            };
        case "UPDATE_PROJECT":
            return {
                ...state,
                projects: state.projects.map((project) => project.id === action.project.projectid ? action.project : project
                ),
            };
        case "REMOVE_PROJECT":
            return {
                ...state,
                projects: state.projects.filter((project) => project.id !== action.projectid),
            };
        case "ADD_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: [...state.project.currencies, action.currency]
                }
            };
        case "UPDATE_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: state.project.currencies.map(item =>
                        item.id === action.currency.id ? { ...item, ...action.currency } : item
                    )
                }
            };
        case "REMOVE_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    currencies: state.project.currencies.filter(item => item.id !== action.currencyid)
                }
            };
        case "MAKE_DEFAULT_CURRENCY":
            return {
                ...state,
                project: {
                    ...state.project,
                    basecurrency: action.currencyid
                }
            };
        case "ADD_ITEM":
            return {
                ...state,
                project: {
                    ...state.project,
                    inventory: [...state.project.inventory, action.item]
                }
            };
        case "UPDATE_ITEM":
            return {
                ...state,
                project: {
                    ...state.project,
                    inventory: state.project.inventory.map(item =>
                        item.id === action.item.id ? action.item : item
                    )
                }
            };
        case "REMOVE_ITEM":
            return {
                ...state,
                project: {
                    ...state.project,
                    inventory: state.project.inventory.filter(item => item.id !== action.itemid)
                }
            };
        case "ADD_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: [...state.project.journalentries, action.journalentry]
                }
            };
        case "UPDATE_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: state.project.journalentries.map(journal =>
                        journal.id === action.journalentry.id ? action.journalentry : journal
                    )
                }
            };
        case "REMOVE_JOURNALENTRY":
            return {
                ...state,
                project: {
                    ...state.project,
                    journalentries: state.project.journalentries.filter(journal => journal.id !== action.id)
                }
            };
        case "ADD_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: [...state.project.cashaccounts, action.cashaccount]
                }
            };
        case "UPDATE_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: state.project.cashaccounts.map(item =>
                        item.id === action.cashaccount.id ? { ...item, ...action.cashaccount } : item
                    )
                }
            };
        case "REMOVE_CASHACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    cashaccounts: state.project.cashaccounts.filter(item => item.id !== action.cashaccountid)
                }
            };
        case "ADD_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: [...state.project.clients, action.client]
                }
            };
        case "UPDATE_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: state.project.clients.map(client =>
                        client.id === action.client.id ? action.client : client
                    )
                }
            };
        case "REMOVE_CLIENT":
            return {
                ...state,
                project: {
                    ...state.project,
                    clients: state.project.clients.filter(client => client.id !== action.id)
                }
            };
        case "ADD_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: [...state.project.suppliers, action.supplier]
                }
            };
        case "UPDATE_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: state.project.suppliers.map(supplier =>
                        supplier.id === action.supplier.id ? action.supplier : supplier
                    )
                }
            };
        case "REMOVE_SUPPLIER":
            return {
                ...state,
                project: {
                    ...state.project,
                    suppliers: state.project.suppliers.filter(supplier => supplier.id !== action.supplierid)
                }
            };
        case "ADD_SALESINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    salesinvoices: [...state.project.salesinvoices, action.salesinvoice]
                }
            };
        case "UPDATE_SALESINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    salesinvoices: state.project.salesinvoices.map(invoice =>
                        invoice.id === action.salesinvoice.id ? action.salesinvoice : invoice
                    )
                }
            };
        case "REMOVE_SALESINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    salesinvoices: state.project.salesinvoices.filter(invoice => invoice.id !== action.salesinvoiceid)
                }
            };
        case "ADD_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: [...state.project.transactions, action.transaction]
                }
            };
        case "UPDATE_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: state.project.transactions.map(transaction =>
                        transaction.id === action.transaction.id ? action.transaction : transaction
                    )
                }
            };
        case "REMOVE_TRANSACTION":
            return {
                ...state,
                project: {
                    ...state.project,
                    transactions: state.project.transactions.filter(transaction => transaction.id !== action.id)
                }
            };
        case "ADD_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: [...state.project.interaccounttransfers, action.interaccounttransfer]
                }
            };
        case "UPDATE_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: state.project.interaccounttransfers.map(interaccounttransfer =>
                        interaccounttransfer.id === action.interaccounttransfer.id ? action.interaccounttransfer : interaccounttransfer
                    )
                }
            };
        case "REMOVE_INTERACCOUNTTRANSFER":
            return {
                ...state,
                project: {
                    ...state.project,
                    interaccounttransfers: state.project.interaccounttransfers.filter(interaccounttransfer => interaccounttransfer.id !== action.interaccounttransferid)
                }
            };
        case "ADD_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: [...state.project.purchaseinvoices, action.purchaseinvoice]
                }
            };
        case "UPDATE_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: state.project.purchaseinvoices.map(invoice =>
                        invoice.id === action.purchaseinvoice.id ? action.purchaseinvoice : invoice
                    )
                }
            };
        case "REMOVE_PURCHASEINVOICE":
            return {
                ...state,
                project: {
                    ...state.project,
                    purchaseinvoices: state.project.purchaseinvoices.filter(invoice => invoice.id !== action.purchaseinvoiceid)
                }
            };
        case "ADD_QUOTATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    quotations: [...state.project.quotations, action.quotation]
                }
            };
        case "UPDATE_QUOTATIONS":
            return {
                ...state,
                project: {
                    ...state.project,
                    quotations: state.project.quotations.map(quote =>
                        quote.id === action.quote.id ? action.quote : quote
                    )
                }
            };
        case "REMOVE_QUOTATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    quotations: state.project.quotations.filter(quote => quote.id !== action.quotationid)
                }
            };
        case "ADD_PROJECTDETAILS":
            return {
                ...state,
                project: {
                    ...state.project,
                    projectdetails: action.projectdetails,
                    projectdetailsimage: action.projectdetailsimage
                }
            };
        case "REMOVE_PROJECTDETAILSIMAGE":
            return {
                ...state,
                project: {
                    ...state.project,
                    projectdetailsimage: ''
                }
            };
        case "ADD_STARTDATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    startdate: action.startdate
                }
            };
        case "ADD_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: [...state.project.incomeaccounts, action.incomeaccount]
                }
            };
        case "UPDATE_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: state.project.incomeaccounts.map(account =>
                        account.id === action.incomeaccount.id ? action.incomeaccount : account
                    )
                }
            };
        case "REMOVE_INCOMEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    incomeaccounts: state.project.incomeaccounts.filter(account => account.id !== action.incomeaccountid)
                }
            };
        case "ADD_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: [...state.project.expenseaccounts, action.expenseaccount]
                }
            };
        case "UPDATE_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: state.project.expenseaccounts.map(account =>
                        account.id === action.expenseaccount.id ? action.expenseaccount : account
                    )
                }
            };
        case "REMOVE_EXPENSEACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    expenseaccounts: state.project.expenseaccounts.filter(account => account.id !== action.expenseaccountid)
                }
            };
        case "ADD_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: [...state.project.exchangerates, action.exchangerate]
                }
            };
        case "UPDATE_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: state.project.exchangerates.map(rate =>
                        rate.id === action.exchangerate.id ? action.exchangerate : rate
                    )
                }
            };
        case "REMOVE_EXCHANGERATE":
            return {
                ...state,
                project: {
                    ...state.project,
                    exchangerates: state.project.exchangerates.filter(rate => rate.id !== action.exchangerateid)
                }
            };
        case "ADD_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: [...state.project.subaccounts, action.subaccount]
                }
            };
        case "UPDATE_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: state.project.subaccounts.map(subaccount =>
                        subaccount.id === action.subaccount.id ? action.subaccount : subaccount
                    )
                }
            };
        case "REMOVE_SUBACCOUNT":
            return {
                ...state,
                project: {
                    ...state.project,
                    subaccounts: state.project.subaccounts.filter(subaccount => subaccount.id !== action.subaccountid)
                }
            };
        case "ADD_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: [...state.project.reconciliations, action.reconciliation]
                }
            };
        case "UPDATE_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: state.project.reconciliations.map(reconciliation =>
                        reconciliation.id === action.reconciliation.id ? action.reconciliation : reconciliation
                    )
                }
            };
        case "REMOVE_RECONCILIATION":
            return {
                ...state,
                project: {
                    ...state.project,
                    reconciliations: state.project.reconciliations.filter(reconciliation => reconciliation.id !== action.reconciliationid)
                }
            };
        default:
            return state
    }
}

export default reducer;