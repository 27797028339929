import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import Title from './Title';

import './Reports.css';

function findLatestRate(data, currencyid) {
    if (data.length === 0) {
        return 1;
    }

    let latestRate = 1;

    for (let i = 0; i < data.length; i++) {
        const entry = data[i];

        if (entry.currencyid === currencyid) {
            if (!latestRate || parseInt(entry.ts) > parseInt(latestRate.ts)) {
                latestRate = entry;
            }
        }
    }

    return latestRate ? latestRate.rate : 1;
}

function Reports() {
    const [{ project }, dispatch] = useStateValue();

    const [from, setFrom] = useState(new Date());
    const [fromts, setFromTs] = useState(0);
    const [to, setTo] = useState(new Date());
    const [tots, setToTs] = useState(0);

    const [option, setOption] = useState(null);

    useEffect(() => {
        setFrom(new Date(project.startdate * 1000))
    }, [project]);

    useEffect(() => {
        const unixfromts = Math.floor(from.getTime() / 1000);
        setFromTs(unixfromts)
    }, [from]);

    useEffect(() => {
        const unixtots = Math.floor(to.getTime() / 1000);
        setToTs(unixtots)
    }, [to]);

    const handleProfitLoss = () => {
        setOption(<ProfitLoss />);
    }

    const handleBalanceSheet = () => {
        setOption(<BalanceSheet />);
    }

    const handleCashFlow = () => {
        setOption(<CashFlow />);
    }

    const handleReceiptsPayments = () => {
        setOption(<ReceiptsPayments />);
    }

    function ProfitLoss() {
        const [profit, setProfit] = useState([]);
        const [loss, setLoss] = useState([]);

        useEffect(() => {
            setProfit(project.salesinvoices)
            setLoss(project.expenseaccounts);
        }, [project]);

        const totalInvoiceSum = project.salesinvoices.reduce((total, invoice) => {
            if (invoice.ts >= fromts && invoice.ts <= tots) {
                if (project.basecurrency === invoice.currencyid) {
                    return total + parseFloat(invoice.amount);
                } else {
                    const exchangeRate = findLatestRate(project.exchangerates, invoice.currencyid);
                    const convertedAmount = parseFloat(invoice.amount) / exchangeRate;
                    return total + convertedAmount;
                }
            }
        }, 0);

        return (
            <div className='report'>
                <div className='report_title'>Profit and Loss</div>
                <div className='report_dateframe'>{moment.unix(fromts).format('D/MMM/YYYY')} to {moment.unix(tots).format('D/MMM/YYYY')}</div>
                <table className='table'>
                    <thead><tr><th>Name</th><th></th></tr></thead>
                    <tbody>

                        <tr>
                            <td>Sales Invoices</td>
                            <td>{project.currencies.find((currency) => currency.id === project.basecurrency).symbol} {totalInvoiceSum}</td>
                        </tr>

                        {
                            loss.map((account, index) => {
                                let sum = 0;

                                for (let i = 0; i < project.transactions.length; i++) {
                                    const transaction = project.transactions[i];

                                    if (transaction.ts >= fromts && transaction.ts <= tots) {
                                        if (transaction.to === account.id) {
                                            if (project.basecurrency === transaction.currencyid) {
                                                sum += parseFloat(transaction.amount);
                                            }
                                        }
                                    }
                                }

                                return (
                                    <tr key={'account' + index}>
                                        <td>{account.name}</td>
                                        <td>{project.currencies.find((currency) => currency.id === project.basecurrency).symbol} {sum.toLocaleString('en-US')}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    function BalanceSheet() {
        const [budget, setBudget] = useState([]);

        useEffect(() => {
            setBudget(project.expenseaccounts)
        }, [project]);

        return (
            <div className='report'>
                <div className='report_title'>Balance Sheet</div>
                <div className='report_dateframe'>{moment.unix(fromts).format('D/MMM/YYYY')} to {moment.unix(tots).format('D/MMM/YYYY')}</div>
                <table className='table'>
                    <thead><tr><th>Name</th><th></th></tr></thead>
                    <tbody>
                        <tr><td>Assets</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Liabilities</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Equity</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    function CashFlow() {
        const [budget, setBudget] = useState([]);

        useEffect(() => {
            setBudget(project.expenseaccounts)
        }, [project]);

        return (
            <div className='report'>
                <div className='report_title'>Cash Flow</div>
                <div className='report_dateframe'>{moment.unix(fromts).format('D/MMM/YYYY')} to {moment.unix(tots).format('D/MMM/YYYY')}</div>
                <table className='table'>
                    <thead><tr><th>Name</th><th></th></tr></thead>
                    <tbody>
                        <tr>
                            <td>Operating activities</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cash at begining of the period</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cash at end of the period</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    function ReceiptsPayments() {
        const [budget, setBudget] = useState([]);

        useEffect(() => {
            setBudget(project.expenseaccounts)
        }, [project]);

        return (
            <div className='report'>
                <div className='report_title'>Receipts and Payments</div>
                <div className='report_dateframe'>{moment.unix(fromts).format('D/MMM/YYYY')} to {moment.unix(tots).format('D/MMM/YYYY')}</div>
                <table className='table'>
                    <thead><tr><th></th></tr></thead>
                    <tbody>
                        <tr><td>Receipts</td></tr>
                        <tr><td>Less: Payments</td></tr>
                        <tr><td>Net after end of the period</td></tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className='reports'>
            <Title text='reports' />

            <div className='row'>
                <div className='rowsection'>
                    <div>From</div>
                    <DatePicker
                        selected={from}
                        onChange={(date) => setFrom(date)}
                    />
                </div>
                <div className='rowsection'>
                    <div>To</div>
                    <DatePicker
                        selected={to}
                        onChange={(date) => setTo(date)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <button className='button' onClick={handleProfitLoss}>Trial Balance</button>
                <button className='button' onClick={handleProfitLoss}>Profit and Loss</button>
                <button className='button' onClick={handleBalanceSheet}>Balance Sheet</button>
                <button className='button' onClick={handleCashFlow}>Cash Flow</button>
                <button className='button' onClick={handleReceiptsPayments}>Receipts & Payments</button>
            </div>

            {option}

        </div>
    );
}

export default Reports;