import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';

import axios from 'axios';
import configData from './Config';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Admin from './Admin/Admin';

import Header from './Header.js';

import './Projects.css';

function Projects({ state, setState }) {
    const [{ version, projects }, dispatch] = useStateValue();

    const [listprojects, setListprojects] = useState([]);

    const [archivemode, setArchivemode] = useState(false);

    const [search, setSearch] = useState('');
    const [transactionsprogress, setTransactionsprogress] = useState(null);
    const [transactionsloading, setTransactionsloading] = useState(false);

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(null);

    const navigation = useNavigate();

    useEffect(() => {
        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(search.toLowerCase())
        );
        const sortedProjects = filtered.sort((a, b) => a.name.localeCompare(b.name));
        setListprojects(sortedProjects);
    }, [projects, search]);

    useEffect(() => {
        if (archivemode == false) {
            const data = {
                userid: state.user.userid
            }

            axios.post(configData.CONTROLLERURL + configData.GETPROJECTS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'SET_PROJECTS',
                        projects: res.data
                    });
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false)
            })
        }
        if (archivemode == true) {
            const data = {
                userid: state.user.userid
            }

            axios.post(configData.CONTROLLERURL + configData.GETARCHIVES, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log(res.data)
                if (res.data instanceof Array) {
                    dispatch({
                        type: 'SET_PROJECTS',
                        projects: res.data
                    });
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [archivemode]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const handleAddProjectButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddProject />, modaltype: 'small', modaltitle: 'Add New Project' }));
    }

    const handleTransactionsOverviewButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <TransactionsOverview />, modaltype: 'small', modaltitle: 'Transactions Overview' }));
    }

    const handleAuditTrailButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AuditTrail />, modaltype: 'large', modaltitle: 'Audit Trail' }));
    }

    const handleBackupsButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Backups />, modaltype: 'large', modaltitle: 'Backups' }));
    }

    const handleAdminButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Admin state={state} setState={setState} />, modaltype: 'large', modaltitle: 'Admin' }));
    }

    function AddProject() {
        const [name, setName] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        const addProject = () => {
            console.log('Trying to add project');

            const trimmedname = name.trim();
            if (!trimmedname) {
                setResult('Project name cannot be empty');
                return;
            }

            const projectExists = projects.some(project => project.name.toLowerCase() === trimmedname.toLowerCase());
            if (projectExists) {
                setResult('Project name already exists');
                return;
            }

            setLoading(true);
            setResult('');
            
            const data = {
                projectuserid: state.user.userid,
                name: trimmedname
            }

            axios.post(configData.CONTROLLERURL + configData.ADDPROJECT, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add project data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_PROJECT',
                            project: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={() => addProject()} disabled={loading}>{loading ? 'Adding Project' : 'Add Project'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function TransactionsOverview() {
        async function getAllTransactionsAndExportCSV() {
            let alltransactions = [];

            const data = {
                userid: state.user.userid
            };
            setTransactionsloading(true);
            try {
                const projectsResponse = await axios.post(configData.CONTROLLERURL + configData.GETPROJECTS, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                });

                if (projectsResponse.data instanceof Object) {
                    const totalProjects = projectsResponse.data.length;
                    const transactionPromises = projectsResponse.data.map(async (project, i) => {
                        const projectData = {
                            projectuserid: project.userid,
                            projectid: project.projectid
                        };

                        const projectResponse = await axios.post(configData.CONTROLLERURL + configData.GETPROJECT, projectData, {
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json;charset=UTF-8",
                                "userid": state.user.userid,
                                "usertoken": state.user.usertoken
                            }
                        });

                        if (projectResponse.data.transactions instanceof Array) {
                            const updatedTransactions = projectResponse.data.transactions.map(transaction => ({
                                ...transaction,
                                projectname: project.name,
                                fromname: transaction.type === 'receipt'
                                    ? projectResponse.data.clients.find((client) => client.id === transaction.from)?.name
                                    : projectResponse.data.cashaccounts.find((account) => account.id === transaction.from)?.name,
                                toname: transaction.type === 'receipt'
                                    ? projectResponse.data.cashaccounts.find((account) => account.id === transaction.to)?.name
                                    : projectResponse.data.expenseaccounts.find((account) => account.id === transaction.to)?.name,
                                currencysymbol: projectResponse.data.currencies.find((currency) => currency.id === transaction.currencyid)?.name
                            }));
                            alltransactions.push(...updatedTransactions);
                        }

                        const currentProgress = `${i + 1}/${totalProjects}`;
                        setTransactionsprogress(currentProgress);
                    });

                    await Promise.all(transactionPromises);

                    const escapeTabs = (field) => (typeof field === "string" ? field.replace(/\t/g, " ") : field);

                    const header = ["Key", "Date", "Project", "Type", "ID", "From", "To", "Name", "Amount", "Currency"];
                    const rows = alltransactions.map((transaction, index) => [
                        index + 1,
                        moment.unix(transaction.ts).format('D/MMM/YYYY'),
                        transaction.projectname,
                        transaction.type,
                        transaction.id,
                        transaction.fromname,
                        transaction.toname,
                        escapeTabs(transaction.name),
                        transaction.amount,
                        transaction.currencysymbol
                    ]);

                    const tsvContent = [header, ...rows].map(e => e.join("\t")).join("\n");
                    const blob = new Blob([tsvContent], { type: 'text/tab-separated-values;charset=utf-8;' });
                    saveAs(blob, 'TransactionsOverview.tsv');

                    setTransactionsprogress(null);
                }
            } catch (error) {
                console.error("Failed to fetch transactions or export CSV", error);
            } finally {
                setTransactionsloading(false);
            }
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            This will download all transactions overview
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={() => getAllTransactionsAndExportCSV()}>Download Transactions</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const selectProject = (project, index) => {
        setLoading(index);

        const data = {
            projectuserid: project.userid,
            projectid: project.projectid
        }

        axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log(res.data)
            if (res.data instanceof Object) {
                dispatch({
                    type: 'SET_PROJECT',
                    project: res.data
                });
                navigation('home');
            }
        }).catch(() => {
            setResult('Not connected')
        }).finally(() => {
            setLoading(null);
        });
    }

    const selectArchive = (project, index) => {
        setLoading(index);

        const data = {
            projectuserid: project.userid,
            projectid: project.projectid
        }

        axios.post(configData.CONTROLLERURL + configData.GETARCHIVE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log(res.data)
            if (res.data instanceof Object) {
                dispatch({
                    type: 'SET_PROJECT',
                    project: res.data
                });
                navigation('home');
            }
        }).catch(() => {
            setResult('Not connected')
        }).finally(() => {
            setLoading(null);
        });
    }

    function handleCollectionsOverview() {
        navigation('collectionsoverview');
    }

    function AuditTrail() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);
        const [entries, setEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        const printRef = useRef();

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            const uniqueIds = projects.map(project => project.userid)
                .filter((userId, index, array) => array.indexOf(userId) === index);

            console.log('Trying to get audit entries');
            setLoading(true);

            if (uniqueIds.length > 0) {
                setEntries([])
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId,
                        ts: timestamp
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETAUDITTRAIL, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Audit entries data received');
                        if (res.data instanceof Array) {
                            setEntries(prevEntries => [...prevEntries, ...res.data]);
                        }
                    }).catch((err) => {
                        setResult(err.response?.data?.message || err.message || 'Network error');
                    }).finally(() => {
                        setLoading(false);
                    })
                });
            }
        }, [timestamp]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            {moment(timestamp * 1000).format('dddd, Do MMMM YYYY')}
                        </div>
                    </div>

                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Username</th>
                                        <th>Project Name</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && <tr><td colSpan={4}><div>loading...</div></td></tr>}
                                    {
                                        entries.map((entry, index) => {
                                            return (
                                                <tr key={'entry' + index}>
                                                    <td><div>{moment.unix(entry.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                                    <td><div>{entry.username}</div></td>
                                                    <td><div>{projects.find(project => project.projectid === entry.projectid)?.name}</div></td>
                                                    <td><div>{entry.description}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function Backups() {
        const [backupprogress, setBackupprogress] = useState(null);
        const [backupprogressarchive, setBackupprogressarchive] = useState(null);
        const [backups, setBackups] = useState([]);

        const [refresh, setRefresh] = useState(false);
        const [loading, setLoading] = useState(false);

        const [result, setResult] = useState('');

        useEffect(() => {
            const uniqueIds = projects.map(project => project.userid)
                .filter((userId, index, array) => array.indexOf(userId) === index);

            console.log('Trying to get backups');
            setLoading(true);

            if (uniqueIds.length > 0) {
                setBackups([])
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETBACKUPS, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Backups data received')
                        console.log(res.data)
                        if (res.data instanceof Array) {
                            setBackups(prevEntries => [...prevEntries, ...res.data]);
                        }
                    }).catch((err) => {
                        setResult(err.response?.data?.message || err.message || 'Network error');
                    }).finally(() => {
                        setLoading(false);
                    })
                });
            }
        }, [refresh]);

        useEffect(() => {
            if (backups.length > 0) {
                const lastBackup = backups.reduce((latest, backup) =>
                    backup.ts > latest.ts ? backup : latest
                );

                const lastBackupTime = new Date(lastBackup.ts * 1000);
                const now = new Date();

                const diffMs = now - lastBackupTime;
                const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
                const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                setResult('Last backup was from ' + diffHours + ' hours and ' + diffMinutes + ' minutes');
            }
        }, [backups]);

        const addBackup = () => {
            const uniqueIds = projects.map(project => project.userid)
                .filter((userId, index, array) => array.indexOf(userId) === index);

            if (uniqueIds.length > 0) {
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.ADDBACKUP, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Backup data received')
                        console.log(res.data)
                        if (res.data instanceof Object) {
                            if (res.data.code == 1) {
                                setRefresh(!refresh)
                            }
                            else {
                                setResult(res.data.data);
                            }
                        }
                        else {
                            setResult('Error');
                        }
                    }).catch((err) => {
                        setResult(err.response?.data?.message || err.message || 'Network error');
                    }).finally(() => {
                        setLoading(false);
                    })
                })
            }
        }

        const addArchivebackup = () => {
            const uniqueIds = projects.map(project => project.userid)
                .filter((userId, index, array) => array.indexOf(userId) === index);

            if (uniqueIds.length > 0) {
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.ADDARCHIVEBACKUP, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Backup data received')
                        console.log(res.data)
                        if (res.data instanceof Object) {
                            if (res.data.code == 1) {
                                setRefresh(!refresh)
                            }
                            else {
                                setResult(res.data.data);
                            }
                        }
                        else {
                            setResult('Error');
                        }
                    }).catch((err) => {
                        setResult(err.response?.data?.message || err.message || 'Network error');
                    }).finally(() => {
                        setLoading(false);
                    })
                })
            }
        }

        const downloadBackup = async () => {
            setResult(null)

            if (archivemode == true) {
                setResult('Make sure to remove acrhive mode first')
                return
            }

            const zip = new JSZip();
            const today = moment().format('YYYYMMDD');
            const backupFileName = "backup" + today + ".zip";

            for (let i = 0; i < projects.length; i++) {
                const project = projects[i];

                const data = {
                    projectuserid: project.userid,
                    projectid: project.projectid
                };

                try {
                    const res = await axios.post(configData.CONTROLLERURL + configData.GETPROJECT, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    });

                    if (res.data instanceof Object) {
                        res.data.version = version;
                        const pdContent = JSON.stringify(res.data);
                        zip.file(project.projectid + ".pd", pdContent);
                    }

                    const currentProgress = i + 1 + "/" + projects.length;
                    setBackupprogress(currentProgress);
                } catch (err) {
                    setResult(err);
                }
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, backupFileName);

            setBackupprogress(null);
        };

        const downloadArchiveBackup = async () => {
            setResult(null)

            if (archivemode == false) {
                setResult('Make sure to toggle acrhive mode first')
                return
            }

            const zip = new JSZip();
            const today = moment().format('YYYYMMDD');
            const backupFileName = "archivebackup" + today + ".zip";

            for (let i = 0; i < projects.length; i++) {
                const project = projects[i];

                const data = {
                    projectuserid: project.userid,
                    projectid: project.projectid
                };

                try {
                    const res = await axios.post(configData.CONTROLLERURL + configData.GETARCHIVE, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    });

                    if (res.data instanceof Object) {
                        res.data.version = version;
                        const pdContent = JSON.stringify(res.data);
                        zip.file(project.projectid + ".pd", pdContent);
                    }

                    const currentProgress = i + 1 + "/" + projects.length;
                    setBackupprogressarchive(currentProgress);
                } catch (err) {
                    setResult(err);
                }
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, backupFileName);

            setBackupprogressarchive(null);
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cloud</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='buttonscontainer'>
                                <div className='buttonsection'>
                                    <button className='button' onClick={addBackup} disabled={loading}>Add Backup</button>
                                </div>
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='buttonscontainer'>
                                <div className='buttonsection'>
                                    <button className='button' onClick={addArchivebackup} disabled={loading}>Add Archive Backup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Download</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='buttonscontainer'>
                                <div className='buttonsection'>
                                    <button className='button' onClick={downloadBackup} disabled={loading}>Download Backup {backupprogress && '(' + backupprogress + ')'}</button>
                                </div>
                            </div>
                        </div>
                        <div className='modal_rowsection'>
                            <div className='buttonscontainer'>
                                <div className='buttonsection'>
                                    <button className='button' onClick={downloadArchiveBackup} disabled={loading}>Download Archive Backup {backupprogressarchive && '(' + backupprogressarchive + ')'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result && <div className='modal_result'>{result}</div>}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date/Time</th>
                                        <th>Archived</th>
                                        <th>ProjectUserId</th>
                                        <th>Size</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        backups.map((backup, index) => {
                                            return (
                                                <tr key={'backup' + index}>
                                                    <td><div>{backup.id}</div></td>
                                                    <td><div>{moment.unix(backup.ts).format('D/MMM/YYYY hh:mm:ss A')}</div></td>
                                                    <td><div>{backup.archived == 1 ? 'Archived' : 'Not archived'}</div></td>
                                                    <td><div>{backup.projectuserid}</div></td>
                                                    <td><div>{(backup.size / 1024).toFixed(2)}KB</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='projects'>
            <Header state={state} setState={setState} />

            <div className='projects_options'>
                {state.user.usertype <= 2 && <button className='projects_optionsbutton' onClick={handleAddProjectButton}>Add Project</button>}
                <button className='projects_optionsbutton' onClick={() => handleTransactionsOverviewButton()} disabled={transactionsloading}>Transactions Overview {transactionsloading ? 'Loading...' : ''} {transactionsprogress}</button>
                <button className='projects_optionsbutton' onClick={() => handleCollectionsOverview()}>Collections Overview</button>
                <button className='projects_optionsbutton' onClick={() => handleAuditTrailButton()}>Audit Trail</button>
                <button className='projects_optionsbutton' onClick={() => handleBackupsButton()}>Backups</button>
                {(state.user.usertype == 2) && <button className='projects_optionsbutton' onClick={() => handleAdminButton()}>Admin</button>}
                <button className={archivemode ? 'projects_optionsbutton projects_optionsbuttontoggle' : 'projects_optionsbutton'} onClick={() => setArchivemode(!archivemode)}>Archive</button>
            </div>

            <div className='projects_searchcontainer'>
                <input
                    className="projects_searchinput"
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className='projects_optionstitle'>{archivemode == true ? 'Archive' : 'Projects'} ({listprojects.length})</div>
            <div className='result'>{result && result}</div>
            <div className='projects_projectscontainer'>
                <div className='projects_seperator' />
                {
                    listprojects.map((project, index) => {
                        if (project.archived == false) {
                            return (
                                <div key={'project' + index} className='projects_projectbutton'>
                                    <div className='projects_projecttext' onClick={() => selectProject(project, index)} disabled={loading}>{project.name} {loading === index && '(loading...)'}</div>
                                    <div className='projects_projectsize'>{project.size} KB</div>
                                </div>
                            )
                        }
                        if (project.archived == true) {
                            return (
                                <div key={'project' + index} className='projects_projectbutton'>
                                    <div className='projects_projecttext' onClick={() => selectArchive(project, index)} disabled={loading}>{project.name} {loading === index && '(loading...)'}</div>
                                    <div className='projects_projectsize'>{project.size} KB</div>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>

    );
}

export default Projects;