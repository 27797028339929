import { findNearestExchangeRate } from './currency.js';

export function getChartAccounts(incomegroups, incomeaccounts, expensegroups, expenseaccounts, currencies) {
    const resultArray = [];

    resultArray.push({
        orderid: '',
        name: "Income",
        currency: '',
        amount: '',
        type: 'title'
    });

    incomegroups.sort((a, b) => a.orderid - b.orderid);
    incomegroups.forEach(group => {
        resultArray.push({
            id: group.id,
            orderid: group.orderid,
            name: group.name,
            currency: '',
            amount: '',
            type: 'incomegroup'
        });

        const groupAccounts = incomeaccounts.filter(account => account.groupid === group.id);
        groupAccounts.sort((a, b) => a.orderid - b.orderid);
        groupAccounts.forEach(account => {
            const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
            resultArray.push({
                id: account.id,
                orderid: account.orderid,
                groupid: account.groupid,
                name: account.name,
                currencyid: account.currencyid,
                currencysymbol: currencySymbol,
                budget: account.budget,
                type: 'incomeaccount'
            });
        });
    });

    const ungroupedIncomeAccounts = incomeaccounts.filter(account => !account.groupid);
    ungroupedIncomeAccounts.sort((a, b) => a.orderid - b.orderid);
    ungroupedIncomeAccounts.forEach(account => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
        resultArray.push({
            id: account.id,
            orderid: account.orderid,
            groupid: account.groupid,
            name: account.name,
            currencyid: account.currencyid,
            currencysymbol: currencySymbol,
            budget: account.budget,
            type: 'incomeaccount'
        });
    });

    resultArray.push({
        orderid: '',
        name: "Expense",
        currency: '',
        amount: '',
        type: 'title'
    });

    expensegroups.sort((a, b) => a.orderid - b.orderid);
    expensegroups.forEach(group => {
        resultArray.push({
            id: group.id,
            orderid: group.orderid,
            name: group.name,
            currency: '',
            amount: '',
            type: 'expensegroup'
        });

        const groupAccounts = expenseaccounts.filter(account => account.groupid === group.id);
        groupAccounts.sort((a, b) => a.orderid - b.orderid);
        groupAccounts.forEach(account => {
            const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
            resultArray.push({
                id: account.id,
                orderid: account.orderid,
                groupid: account.groupid,
                name: account.name,
                currencyid: account.currencyid,
                currencysymbol: currencySymbol,
                budget: account.budget,
                type: 'expenseaccount'
            });
        });
    });

    const ungroupedExpenseAccounts = expenseaccounts.filter(account => !account.groupid);
    ungroupedExpenseAccounts.sort((a, b) => a.orderid - b.orderid);
    ungroupedExpenseAccounts.forEach(account => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
        resultArray.push({
            id: account.id,
            orderid: account.orderid,
            groupid: account.groupid,
            name: account.name,
            currencyid: account.currencyid,
            currencysymbol: currencySymbol,
            budget: account.budget,
            type: 'expenseaccount'
        });
    });

    return resultArray;
}

export function getIncomeAccounts(incomegroups, incomeaccounts, journalentries, exchangerates, currencies) {
    const resultArray = [];

    const sortedIncomeGroups = [...incomegroups].sort((a, b) => a.orderid - b.orderid);
    sortedIncomeGroups.forEach(group => {
        resultArray.push({
            id: group.id,
            orderid: group.orderid,
            name: group.name,
            currency: '',
            amount: '',
            type: 'incomegroup'
        });

        const groupAccounts = [...incomeaccounts].filter(account => account.groupid === group.id);
        const sortedGroupAccounts = groupAccounts.sort((a, b) => a.orderid - b.orderid);

        sortedGroupAccounts.forEach(account => {
            const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
            let sum = 0;

            journalentries.forEach((entry) => {
                entry.rows.forEach((row) => {
                    if (row.accountid === account.id) {
                        const convertedDebit = parseFloat(row.debit);
                        const convertedCredit = parseFloat(row.credit);
                        if (entry.currencyid === account.currencyid) {
                            sum -= convertedDebit;
                            sum += convertedCredit;
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, account.currencyid);
                            const exchangerate = NearestExchangeRate.exchangerate;
                            sum -= convertedDebit * exchangerate;
                            sum += convertedCredit * exchangerate;
                        }
                    }
                });
            });

            resultArray.push({
                id: account.id,
                orderid: account.orderid,
                name: account.name,
                currencyid: account.currencyid,
                currencysymbol: currencySymbol,
                amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
                budget: account.budget,
                type: 'incomeaccount'
            });
        });
    });

    const ungroupedIncomeAccounts = [...incomeaccounts].filter(account => !account.groupid);
    const sortedUngroupedAccounts = ungroupedIncomeAccounts.sort((a, b) => a.orderid - b.orderid);

    sortedUngroupedAccounts.forEach(account => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
        let sum = 0;

        journalentries.forEach((entry) => {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    const convertedDebit = parseFloat(row.debit);
                    const convertedCredit = parseFloat(row.credit);
                    if (entry.currencyid === account.currencyid) {
                        sum -= convertedDebit;
                        sum += convertedCredit;
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, account.currencyid);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        sum -= convertedDebit * exchangerate;
                        sum += convertedCredit * exchangerate;
                    }
                }
            });
        });

        resultArray.push({
            id: account.id,
            orderid: account.orderid,
            name: account.name,
            currencyid: account.currencyid,
            currencysymbol: currencySymbol,
            amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
            budget: account.budget,
            type: 'incomeaccount'
        });
    });

    return resultArray;
}

export function getExpenseAccounts(expensegroups, expenseaccounts, transactions, journalentries, exchangerates, currencies) {
    const resultArray = [];

    const sortedExpenseGroups = [...expensegroups].sort((a, b) => a.orderid - b.orderid);
    sortedExpenseGroups.forEach(group => {
        resultArray.push({
            id: group.id,
            orderid: group.orderid,
            name: group.name,
            currency: '',
            amount: '',
            type: 'expensegroup'
        });

        const groupAccounts = [...expenseaccounts].filter(account => account.groupid === group.id);
        const sortedGroupAccounts = groupAccounts.sort((a, b) => a.orderid - b.orderid);

        sortedGroupAccounts.forEach(account => {
            const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
            let sum = 0;

            journalentries.forEach((entry) => {
                entry.rows.forEach((row) => {
                    if (row.accountid === account.id) {
                        if (account.currencyid === entry.currencyid) {
                            sum += parseFloat(row.debit);
                            sum -= parseFloat(row.credit);
                        }
                        else {
                            const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, account.currencyid);
                            const exchangerate = NearestExchangeRate.exchangerate;
                            sum += parseFloat(row.debit) * exchangerate;
                            sum -= parseFloat(row.credit) * exchangerate;
                        }
                    }
                });
            });

            transactions.forEach((transaction) => {
                if (transaction.to === account.id) {
                    if (account.currencyid === transaction.currencyid) {
                        sum += parseFloat(transaction.amount);
                    } else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        sum += parseFloat(transaction.amount) * exchangerate;
                    }
                }
            });

            resultArray.push({
                id: account.id,
                orderid: account.orderid,
                name: account.name,
                currencyid: account.currencyid,
                currencysymbol: currencySymbol,
                amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
                budget: account.budget,
                type: 'expenseaccount'
            });
        });
    });

    const ungroupedExpenseAccounts = [...expenseaccounts].filter(account => !account.groupid);
    const sortedUngroupedAccounts = ungroupedExpenseAccounts.sort((a, b) => a.orderid - b.orderid);

    sortedUngroupedAccounts.forEach(account => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
        let sum = 0;

        journalentries.forEach((entry) => {
            entry.rows.forEach((row) => {
                if (row.accountid === account.id) {
                    if (account.currencyid === entry.currencyid) {
                        sum += parseFloat(row.debit);
                        sum -= parseFloat(row.credit);
                    }
                    else {
                        const NearestExchangeRate = findNearestExchangeRate(exchangerates, entry.ts, entry.currencyid, account.currencyid);
                        const exchangerate = NearestExchangeRate.exchangerate;
                        sum += parseFloat(row.debit) * exchangerate;
                        sum -= parseFloat(row.credit) * exchangerate;
                    }
                }
            });
        });

        transactions.forEach((transaction) => {
            if (transaction.to === account.id) {
                if (account.currencyid === transaction.currencyid) {
                    sum += parseFloat(transaction.amount);
                } else {
                    const NearestExchangeRate = findNearestExchangeRate(exchangerates, transaction.ts, transaction.currencyid, account.currencyid);
                    const exchangerate = NearestExchangeRate.exchangerate;
                    sum += parseFloat(transaction.amount) * exchangerate;
                }
            }
        });

        resultArray.push({
            id: account.id,
            orderid: account.orderid,
            name: account.name,
            currencyid: account.currencyid,
            currencysymbol: currencySymbol,
            amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
            budget: account.budget,
            type: 'expenseaccount'
        });
    });

    return resultArray;
}

export function getExpenseBudget(expensegroups, expenseaccounts, currencies) {
    const resultArray = [];

    const sortedExpenseGroups = [...expensegroups].sort((a, b) => a.orderid - b.orderid);
    sortedExpenseGroups.forEach(group => {
        resultArray.push({
            id: group.id,
            orderid: group.orderid,
            name: group.name,
            currency: '',
            amount: '',
            type: 'expensegroup'
        });

        const groupAccounts = [...expenseaccounts].filter(account => account.groupid === group.id);
        const sortedGroupAccounts = groupAccounts.sort((a, b) => a.orderid - b.orderid);

        sortedGroupAccounts.forEach(account => {
            const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
            let sum = 0;

            resultArray.push({
                id: account.id,
                orderid: account.orderid,
                name: account.name,
                currencyid: account.currencyid,
                currencysymbol: currencySymbol,
                amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
                budget: account.budget,
                type: 'expenseaccount'
            });
        });
    });

    const ungroupedExpenseAccounts = [...expenseaccounts].filter(account => !account.groupid);
    const sortedUngroupedAccounts = ungroupedExpenseAccounts.sort((a, b) => a.orderid - b.orderid);

    sortedUngroupedAccounts.forEach(account => {
        const currencySymbol = currencies.find((currency) => currency.id === account.currencyid)?.symbol;
        let sum = 0;

        resultArray.push({
            id: account.id,
            orderid: account.orderid,
            name: account.name,
            currencyid: account.currencyid,
            currencysymbol: currencySymbol,
            amount: sum.toLocaleString("en-US", { minimumFractionDigits: Number.isInteger(sum) ? 0 : 2, maximumFractionDigits: 2 }),
            budget: account.budget,
            type: 'expenseaccount'
        });
    });

    return resultArray;
}
