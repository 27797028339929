import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import Title from './Title';
import StatementAccount from './StatementAccount';
import { getSupplierAccounts } from './Utils/suppliers.js';
import { journalentryremovalprotection, transactionsremovalprotection, purchaseinvoicesremovalprotection, contractsremovalprotection } from './Utils/removeprotection.js';

import './Suppliers.css';

function Suppliers({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredsuppliers, setFilteredsuppliers] = useState([]);
    const [search, setSearch] = useState('');

    const suppliersdata = getSupplierAccounts(filteredsuppliers, project.transactions, project.purchaseinvoices, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const filtered = project.suppliers.filter((supplier) =>
            supplier.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredsuppliers(filtered)
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddSupplier />, modaltype: 'small', modaltitle: 'Add Supplier' }));
    }

    const editButton = (supplier) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditSupplier supplier={supplier} />, modaltype: 'small', modaltitle: 'Edit Supplier' }));
    }

    const viewButton = (supplier) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewSupplier supplier={supplier} />, modaltype: 'small', modaltitle: 'View Supplier' }));
    }

    const removeButton = (supplier) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveSupplier supplier={supplier} />, modaltype: 'small', modaltitle: 'Remove Supplier' }));
    }

    const handleStatement = (type, account) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <StatementAccount state={state} setState={setState} type={type} account={account} />, modaltype: 'large', modaltitle: 'Statement of Account' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.suppliers || project.suppliers.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.suppliers, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);
        const [importType, setImporttype] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (isJSON) {
                setImporttype('json');
                const requiredAttributes = ["id", "name"];
                const isValidData = data.every(entry =>
                    requiredAttributes.every(attr => entry.hasOwnProperty(attr))
                );

                if (isValidData) {
                    const newEntries = data.filter(entry => {
                        return !project.suppliers.some(ac => ac.id === entry.id);
                    });

                    if (newEntries.length > 0) {
                        setNewEntries(newEntries);
                        setResult('Data is valid. New entries found: ' + newEntries.length);
                    }
                    else {
                        setResult(data.length + " entries found. No new data.");
                    }
                }
                else {
                    setResult('Invalid data: Missing required attributes.');
                }
            }

            if (!isJSON) {
                setImporttype('tsv');
                const requiredAttributes = ["Name"];
                let rows = importText.split('\n').map(row => row.split('\t'));
                const headers = rows.shift().map(header => header.trim().toLowerCase());

                const missingHeaders = requiredAttributes.filter(attr =>
                    !headers.includes(attr.toLowerCase())
                );

                if (headers.length === 0 || headers.length !== requiredAttributes.length || missingHeaders.length > 0) {
                    setResult(`Invalid headers detected. Missing headers: ${missingHeaders.join(', ')}`);
                    return;
                }

                if (rows.length === 0) {
                    setResult('No data rows detected.');
                    return;
                }

                const existingNames = project.suppliers.map(supplier => (supplier.name || '').toLowerCase());

                data = rows.map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index]?.trim() || '';
                    });
                    return entry;
                });

                const convertedEntries = [];

                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    const name = entry['Name'] || '';

                    if (!existingNames.includes(name.toLowerCase())) {
                        convertedEntries.push({ name });
                    }
                }

                if (convertedEntries.length > 0) {
                    setNewEntries(convertedEntries);
                    setResult('Data is valid. New entries found: ' + newEntries.length);
                }
                else {
                    setResult('No new data found');
                }
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true)
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                suppliers: newEntries,
                type: importType
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTSUPPLIERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_SUPPLIERS',
                            suppliers: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function AddSupplier() {
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        const addSupplier = (name) => {
            console.log('Trying to add supplier');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Suppliers',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.ADDSUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_SUPPLIER',
                            supplier: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addSupplier(name)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        const updateSupplier = (id, name) => {
            console.log('Trying to update supplier');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Suppliers',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!name) {
                setResult('Missing info');
                return;
            }

            const trimmedName = name.trim();

            const isNameDuplicate = project.suppliers.some(supplier => supplier.name === trimmedName && supplier.id !== id);
            if (isNameDuplicate) {
                setResult('Supplier with the same name already exists');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                name: trimmedName
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_SUPPLIER',
                            supplier: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateSupplier(id, name)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(supplier)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveSupplier({ supplier }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(supplier.id);
        }, [supplier]);

        const removeSupplier = (id) => {
            console.log('Trying to remove supplier');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Suppliers',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            const journalEntryData = journalentryremovalprotection(project.journalentries, id, 'suppliers');
            if (journalEntryData.code) {
                setResult(journalEntryData.message);
                return
            }

            const transactionData = transactionsremovalprotection(project.transactions, id, 'suppliers');
            if (transactionData.code) {
                setResult(transactionData.message);
                return
            }

            const purchaseinvoiceData = purchaseinvoicesremovalprotection(project.purchaseinvoices, id, 'suppliers');
            if (purchaseinvoiceData.code) {
                setResult(purchaseinvoiceData.message);
                return
            }

            const contractData = contractsremovalprotection(project.contracts, id, 'suppliers');
            if (contractData.code) {
                setResult(contractData.message);
                return
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVESUPPLIER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove supplier data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_SUPPLIER',
                            supplierid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeSupplier(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewSupplier({ supplier }) {
        const [id, setId] = useState('');
        const [name, setName] = useState('');

        const printRef = useRef();

        useEffect(() => {
            setId(supplier.id);
            setName(supplier.name);
        }, [supplier]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='suppliers'>
            <Title text='Suppliers' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>New Supplier</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Name</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        suppliersdata.map((supplier, index) => {

                            return (
                                <tr key={'supplier' + index}>
                                    <td><div className='table_button' onClick={() => editButton(supplier)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(supplier)}>View</div></td>
                                    <td><div>{supplier.name}</div></td>
                                    <td>
                                        <div
                                            className='cash_balance'
                                            onClick={() => handleStatement(
                                                'suppliers',
                                                {
                                                    id: supplier.id,
                                                    name: supplier.name,
                                                    from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                    to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                    cashaccount: 0,
                                                    subaccountid: 0,
                                                    currencyid: project.basecurrency,
                                                })
                                            }
                                        >{supplier.currency} {supplier.balance.toLocaleString('en-US')}</div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Suppliers;