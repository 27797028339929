import React, { useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import StatementAccount from "./StatementAccount";

import Title from './Title';

import { getClientReceivables, getCashSummary, getExpenseSummary, getIncomeSummary, getLiabilitySummary } from './Utils/summary.js';

import './Summary.css';

function Summary({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const clientsdata = getClientReceivables(project.clients, project.transactions, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);
    const cashdata = getCashSummary(project.cashaccounts, project.transactions, project.interaccounttransfers, project.currencies, project.exchangerates, project.basecurrency);
    const liabilitydata = getLiabilitySummary(project.suppliers, project.transactions, project.purchaseinvoices, project.journalentries, project.exchangerates, project.currencies, project.basecurrency);

    const incomedata = getIncomeSummary(project.incomegroups, project.incomeaccounts, project.journalentries, project.exchangerates, project.currencies);
    const expensedata = getExpenseSummary(project.expensegroups, project.expenseaccounts, project.transactions, project.journalentries, project.exchangerates, project.currencies);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleAccountStatement = (type, account) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <StatementAccount state={state} setState={setState} type={type} account={account} />, modaltype: 'large', modaltitle: 'Statement of Account' }));
    };

    return (
        <div className='summary'>
            <Title text='Summary' />

            <div className='summary_columncontainer'>

                <div className='summary_column'>
                    <div className='summary_columntitle'>Clients</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>
                            <div className='summary_columnsectiontitletext'>Receivables</div>
                        </div>
                        <table className='summary_table'>
                            <tbody>
                                {
                                    clientsdata.map((client, index) => {
                                        let clientBalanceLoc = client.balance.toLocaleString('en-US', { minimumFractionDigits: Number.isInteger(client.balance) ? 0 : 2, maximumFractionDigits: 2 });
                                        return (
                                            <tr key={'client' + index}>
                                                <td><div>{client.name}</div></td>
                                                <td>
                                                    <div
                                                        className='summary_total'
                                                        onClick={() =>
                                                            handleAccountStatement(
                                                                "clients",
                                                                {
                                                                    id: client.id,
                                                                    name: client.name,
                                                                    from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                                    to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                                    subaccountid: 0,
                                                                    currencyid: project.basecurrency,
                                                                }
                                                            )
                                                        }>
                                                        {client.currency + ' ' + clientBalanceLoc}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='summary_columncontainer'>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Cash Status</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>
                            <div className='summary_columnsectiontitletext'>Cash & Bank Accounts</div>
                        </div>
                        <table className='summary_table'>
                            <tbody>
                                {
                                    cashdata.map((data, index) => {
                                        return (
                                            <tr key={'data' + index}>
                                                <td><div>{data.name}</div></td>
                                                <td>
                                                    <div
                                                        className='summary_total'
                                                        onClick={() =>
                                                            handleAccountStatement(
                                                                "cashaccounts",
                                                                {
                                                                    id: data.id,
                                                                    name: data.name,
                                                                    openingbalance: data.openingbalance,
                                                                    from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                                    to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                                    currencyid: data.currencyid,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {data.currency + ' ' + data.balance.toLocaleString('en-US')}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Income</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>
                            <div className='summary_columnsectiontitletext'>Income</div>
                        </div>
                        <table className='summary_table'>
                            <tbody>
                                {
                                    incomedata.map((account, index) => {
                                        if (account.type === 'incomegroup') {
                                            return (
                                                <tr key={'account' + index}>
                                                    <td><div className='summary_incomegroup'>{account.name}</div></td>
                                                    <td></td>
                                                </tr>
                                            );
                                        }
                                        if (account.type === 'incomeaccount') {
                                            return (
                                                <tr key={'account' + index}>
                                                    <td><div className='summary_incomeaccount'>{account.name}</div></td>
                                                    <td>
                                                        <div
                                                            className='summary_total'
                                                            onClick={() =>
                                                                handleAccountStatement(
                                                                    "incomeaccounts",
                                                                    {
                                                                        id: account.id,
                                                                        name: account.name,
                                                                        from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                                        to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                                        subaccountid: 0,
                                                                        currencyid: project.basecurrency
                                                                    }
                                                                )
                                                            }>
                                                            {account.currencysymbol + ' ' + account.amount.toLocaleString('en-US')}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='summary_columncontainer'>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Liabilities</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>
                            <div className='summary_columnsectiontitletext'>Payables</div>
                        </div>
                        <table className='summary_table'>
                            <tbody>
                                {
                                    liabilitydata.map((liability, index) => {
                                        return (
                                            <tr key={'liability' + index}>
                                                <td><div>{liability.name}</div></td>
                                                <td>
                                                    <div
                                                        className='summary_total'
                                                        onClick={() =>
                                                            handleAccountStatement(
                                                                "suppliers",
                                                                {
                                                                    id: liability.id,
                                                                    name: liability.name,
                                                                    from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                                    to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                                    cashaccount: 0,
                                                                    subaccountid: 0,
                                                                    currencyid: project.basecurrency,
                                                                }
                                                            )
                                                        }>
                                                        {liability.currency + ' ' + liability.balance}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='summary_column'>
                    <div className='summary_columntitle'>Expenses</div>
                    <div className='summary_columnsection'>
                        <div className='summary_columnsectiontitle'>
                            <div className='summary_columnsectiontitletext'>Expenses</div>
                        </div>
                        <table className='summary_table'>
                            <tbody>
                                {
                                    expensedata.map((account, index) => {
                                        if (account.type === 'expensegroup') {
                                            return (
                                                <tr key={'account' + index}>
                                                    <td><div className='summary_expensegroup'>{account.name}</div></td>
                                                    <td></td>
                                                </tr>
                                            );
                                        }
                                        if (account.type === 'expenseaccount') {
                                            return (
                                                <tr key={'account' + index}>
                                                    <td><div className='summary_expenseaccount'>{account.name}</div></td>
                                                    <td>
                                                        <div
                                                            className='summary_total'
                                                            onClick={() =>
                                                                handleAccountStatement(
                                                                    "expenseaccounts",
                                                                    {
                                                                        id: account.id,
                                                                        name: account.name,
                                                                        from: Math.floor(new Date(new Date(project.startdate * 1000).setHours(0, 0, 0, 0)).getTime() / 1000),
                                                                        to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
                                                                        cashaccount: 0,
                                                                        subaccountid: 0,
                                                                        currencyid: project.basecurrency
                                                                    }
                                                                )
                                                            }>
                                                            {account.currencysymbol + ' ' + account.amount.toLocaleString('en-US')}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary;