import React, { useState, useEffect } from 'react';
import AdminUsers from './AdminUsers';

function Admin({ state, setState }) {

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='modal_body'>
            <div className='modal_row'>
                <div className='modal_rowsection'>
                    <button className='modal_button' onClick={() => setOption(<AdminUsers state={state} setState={setState} />)}>Users</button>
                </div>
            </div>
            {option}
        </div>
    );
}

export default Admin;