import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { getPermission } from './Utils/permissions.js';
import axios from 'axios';
import configData from './Config';

import Header from './Header';
import CashAccounts from './CashAccounts';
import Summary from './Summary';
import ChartAccounts from './ChartAccounts';
import Clients from './Clients';
import Collections from './Collections';
import InteraccountTransfers from './InteraccountTransfers';
import JournalEntries from './JournalEntries';
import PurchaseInvoices from './PurchaseInvoices';
import Reports from './Reports';
import Transactions from './Transactions';
import Reconciliations from './Reconciliations';
import Suppliers from './Suppliers';
import Users from './Users.js';
import Settings from './Settings';
import Payroll from './Payroll';
import SalesInvoices from './SalesInvoices';
import Quotations from './Quotations';
import Inventory from './Inventory';

import { getHomePermission } from './Utils/permissions.js';

import './Home.css';

const smallscreen = 540;

function Home({ state, setState }) {
  const [{ projects, project, version }, dispatch] = useStateValue();

  const [togglemenu, setTogglemenu] = useState(false);
  const [screenwidth, setScreenWidth] = useState(window.innerWidth);

  const [cashaccounts, setCashaccounts] = useState([]);
  const [clients, setClients] = useState([]);
  const [interaccounttransfers, setInteraccounttransfers] = useState([]);
  const [journalentries, setJournalentries] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);
  const [purchaseinvoices, setPurchaseinvoices] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const [option, setOption] = useState(<Summary state={state} setState={setState} />);

  const homemenuRef = useRef(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (project.projectid == null) navigation('/')
  }, []);

  useEffect(() => {
    setCashaccounts(project.cashaccounts);
    setClients(project.clients);
    setReconciliations(project.reconciliations)
    setInteraccounttransfers(project.interaccounttransfers);
    setJournalentries(project.journalentries);
    setPurchaseinvoices(project.purchaseinvoices);
    setSuppliers(project.suppliers);
    setTransactions(project.transactions);
    setUsers(project.users);
  }, [project]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCloseModal = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
  }

  const editProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <EditProject />, modaltype: 'small', modaltitle: 'Edit Project' }));
  }

  const archiveProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <ArchiveProject />, modaltype: 'small', modaltitle: 'Archive Project' }));
  }

  const unarchiveProjectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <UnarchiveProject />, modaltype: 'small', modaltitle: 'Unarchive Project' }));
  }

  const removeButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveProject />, modaltype: 'small', modaltitle: 'Remove Project' }));
  }

  const disconnectButton = () => {
    setState(state => ({ ...state, modalopen: true, modalcontent: <DisconnectProject />, modaltype: 'small', modaltitle: 'Disconnect Project' }));
  }

  function EditProject() {
    const [name, setName] = useState('');

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const updateProject = () => {
      console.log('Trying to update project');

      setResult('');

      const hasPermission = getPermission(
        project.projectuserid,
        project.users,
        state.user.userid,
        'Project',
        'edit',
        project.archived
      );
      if (hasPermission.code == 0) {
        setResult(hasPermission.data);
        return;
      }

      const trimmedname = name.trim();
      if (!trimmedname) {
        setResult('Project name cannot be empty');
        return;
      }

      const projectExists = projects.some(project => project.name.toLowerCase() === trimmedname.toLowerCase());
      if (projectExists) {
        setResult('Project name already exists');
        return;
      }

      setLoading(true);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        name: trimmedname,
        size: ''
      }

      axios.post(configData.CONTROLLERURL + configData.UPDATEPROJECT, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Add project data received')
        console.log(res.data)
        if (res.data instanceof Object) {
          if (res.data.code === 1) {
            dispatch({
              type: 'UPDATE_PROJECT',
              project: data
            });
            navigation('/');
          }
          else {
            setResult(res.data.data);
          }
        }
      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false)
      })
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>
              <input
                className='modal_input'
                type="text"
                placeholder='Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={() => updateProject()} disabled={loading}>Update</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const downloadBackup = () => {
    if (project) {
      project.version = version;
      const projectJSON = JSON.stringify(project);
      const blob = new Blob([projectJSON], { type: 'application/json' });

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      const today = new Date().toLocaleDateString('en-GB');
      const formattedDate = today.replace(/\//g, '_');
      const formattedName = `${project.name.toLowerCase().replace(/\s+/g, '_')}_${formattedDate}`;
      a.download = formattedName + '.pd';
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }

  function ArchiveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const archiveProject = async () => {
      console.log('Trying to archive project');

      setResult('');

      const hasPermission = getPermission(
        project.projectuserid,
        project.users,
        state.user.userid,
        'Archive',
        'add',
        project.archived
      );
      if (hasPermission.code == 0) {
        setResult(hasPermission.data);
        return;
      }

      setLoading(true);

      const allemails = clients.flatMap(client => [{ id: client.id, type: 'client', email: client.email }, ...client.devices.map(device => ({ id: device.id, type: 'device', email: device.email }))]).filter(({ email }) => email);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        emails: allemails.map(({ email }) => email)
      }

      await axios.post(configData.CONTROLLERURL + configData.GETUSERSSTATUS, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then(async (statusRes) => {
        console.log('Clients status data received');
        console.log(statusRes.data);

        if (statusRes.data.some(status => status.connected)) {
          setResult('Some clients are still connected. Cannot archive.');
          setLoading(false);
          return;
        }
        else {
          console.log('No connected clients found')
        }

        const archiveData = {
          projectuserid: project.projectuserid,
          projectid: project.projectid,
        };

        await axios.post(configData.CONTROLLERURL + configData.ADDARCHIVE, archiveData, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "userid": state.user.userid,
            "usertoken": state.user.usertoken
          }
        }).then((res) => {
          console.log('Archive project data received');
          console.log(res.data);
          if (res.data instanceof Object) {
            if (res.data.code === 1) {
              handleCloseModal();
              navigation('/');
            }
            else {
              setResult(res.data.data);
            }
          }
        })

      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to archive project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={archiveProject} disabled={loading}>{loading ? 'Loading...' : 'Archive'}</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function UnarchiveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const unarchiveProject = () => {
      console.log('Trying to unarchive project');

      setResult('');

      const hasPermission = getPermission(
        project.projectuserid,
        project.users,
        state.user.userid,
        'Archive',
        'remove',
        project.archived
      );
      if (hasPermission.code == 0) {
        setResult(hasPermission.data);
        return;
      }

      setLoading(true);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
      }

      axios.post(configData.CONTROLLERURL + configData.REMOVEARCHIVE, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Unarchive project data received')
        console.log(res.data)
        if (res.data instanceof Object) {
          if (res.data.code === 1) {
            handleCloseModal();
            navigation('/');
          }
          else {
            setResult(res.data.data)
          }
        }
        else {
          setResult('Error');
        }
      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to unarchive project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={unarchiveProject} disabled={loading}>{loading ? 'Loading...' : 'Unarchive'}</button>
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function RemoveProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
      if (project.users && project.users.length) {
        setLoading(true);
        setResult('Users found. Remove them first before proceeding.')
      }
    }, [project]);

    const removeProject = () => {
      console.log('Trying to remove project');

      setResult('');

      const hasPermission = getPermission(
        project.projectuserid,
        project.users,
        state.user.userid,
        'Project',
        'remove',
        project.archived
      );
      if (hasPermission.code == 0) {
        setResult(hasPermission.data);
        return;
      }

      if (project.users.length > 0) {
        setResult('Please remove users first')
        return
      }

      if (project.clients.length > 0) {
        setResult('Please remove clients first')
        return
      }

      setLoading(true);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        archived: project.archived
      }

      axios.post(configData.CONTROLLERURL + configData.REMOVEPROJECT, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Remove project data received')
        console.log(res.data)
        if (res.data instanceof Object) {
          if (res.data.code == 1) {
            dispatch({
              type: 'REMOVE_PROJECT',
              projectid: project.id
            });
            navigation('/');
            handleCloseModal();
          }
          else {
            setResult(res.data.data)
          }
        }
        else {
          setResult('Error')
        }
      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }

    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to remove project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              {!loading && <button className="modal_button" onClick={removeProject} disabled={loading}>Remove</button>}
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DisconnectProject() {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    const disconnectProject = () => {
      console.log('Trying to remove project');

      setResult('');

      const hasPermission = getPermission(
        project.projectuserid,
        project.users,
        state.user.userid,
        'Project',
        'remove',
        project.archived
      );
      if (hasPermission.code == 0) {
        setResult(hasPermission.data);
        return;
      }

      setLoading(true);

      const data = {
        projectuserid: project.projectuserid,
        projectid: project.projectid,
        id: state.user.userid,
      }

      axios.post(configData.CONTROLLERURL + configData.REMOVEUSER, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        }
      }).then((res) => {
        console.log('Remove project data received')
        console.log(res.data)
        if (res.data.code === 1) {
          dispatch({
            type: 'REMOVE_PROJECT',
            projectid: project.id
          });
          navigation('/');
        }
      }).catch((err) => {
        setResult(err.response?.data?.message || err.message || 'Network error');
      }).finally(() => {
        setLoading(false);
      });
    }
    return (
      <div className='modal_body'>
        <div className='modal_printable'>
          <div className='modal_row'>
            <div className='modal_rowsection'>Are you sure you want to disconnect from project ?</div>
          </div>
        </div>
        <div className='modal_actions'>
          {result && <div className='modal_result'>{result}</div>}
          <div className='modal_buttons'>
            <div className='modal_buttoncontainer'>
              {!loading && <button className="modal_button" onClick={disconnectProject} disabled={loading}>Disconnect</button>}
            </div>
            <div className='modal_buttoncontainer'>
              <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const homepermission = getHomePermission(project.projectuserid, project.users, state.user.userid);

  const handleClickMenuItem = (selectedOption) => {
    setOption(selectedOption);
    setTogglemenu(false);
  };

  return (
    <div className='home'>
      <Header state={state} setState={setState} />
      <div className='home_projectdetails'>
        <div className='home_projectsection'>
          <div className='home_projecttext'>
            {project.name}
          </div>
        </div>
        {homepermission['Project'] && <div className='home_projectsection'><button className='home_headerbutton' onClick={editProjectButton}>Edit Project</button></div>}
        {homepermission['Project'] &&
          <div className='home_projectsection'>
            {project.archived == false && <div className='home_headerbutton' onClick={archiveProjectButton}>Archive</div>}
            {project.archived == true && <div className='home_headerbutton' onClick={unarchiveProjectButton}>Unarchive</div>}
          </div>
        }
        <div className='home_projectsection'><div className='home_headerbutton' onClick={downloadBackup}>Backup</div> </div>
        {homepermission['Project'] && project.type == 'localproject' && <div className='home_projectsection'><div className='home_headerremovebutton' onClick={removeButton}>Remove</div></div>}
        {homepermission['Project'] && project.type == 'sharedproject' && <div className='home_projectsection'><div className='home_headerremovebutton' onClick={disconnectButton}>Disconnect</div></div>}
      </div>

      <div className='home_content'>
        <div className={screenwidth > smallscreen ? 'home_menu' : (togglemenu ? 'home_menu home_menusmallvisible' : 'home_menu home_menusmallinvisible')} ref={homemenuRef}>
          {homepermission['Summary'] && <div className='home_button' onClick={() => handleClickMenuItem(<Summary state={state} setState={setState} />)}>Summary</div>}
          {homepermission['Cash Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<CashAccounts state={state} setState={setState} />)}>Cash Accounts ({cashaccounts.length})</div>}
          {homepermission['Chart of Accounts'] && <div className='home_button' onClick={() => handleClickMenuItem(<ChartAccounts state={state} setState={setState} />)}>Chart of Accounts</div>}
          {homepermission['Clients'] && <div className='home_button' onClick={() => handleClickMenuItem(<Clients state={state} setState={setState} />)}>Clients ({clients.length})</div>}
          {homepermission['Collections'] && <div className='home_button' onClick={() => handleClickMenuItem(<Collections state={state} setState={setState} />)}>Collections</div>}
          {homepermission['Interaccount Transfers'] && <div className='home_button' onClick={() => handleClickMenuItem(<InteraccountTransfers state={state} setState={setState} />)}>Interaccount Transfers ({interaccounttransfers.length})</div>}
          {homepermission['Inventory'] && <div className='home_button' onClick={() => handleClickMenuItem(<Inventory state={state} setState={setState} />)}>Inventory</div>}
          {homepermission['Journal Entries'] && <div className='home_button' onClick={() => handleClickMenuItem(<JournalEntries state={state} setState={setState} />)}>Journal Entries ({journalentries.length})</div>}
          {homepermission['Payroll'] && <div className='home_button' onClick={() => handleClickMenuItem(<Payroll state={state} setState={setState} />)}>Payroll</div>}
          {homepermission['Purchase Invoices'] && <div className='home_button' onClick={() => handleClickMenuItem(<PurchaseInvoices state={state} setState={setState} />)}>Purchase Invoices ({purchaseinvoices.length})</div>}
          {homepermission['Quotations'] && <div className='home_button' onClick={() => handleClickMenuItem(<Quotations state={state} setState={setState} />)}>Quotations</div>}
          {homepermission['Reconciliations'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reconciliations state={state} setState={setState} />)}>Reconciliations ({reconciliations.length})</div>}
          {homepermission['Reports'] && <div className='home_button' onClick={() => handleClickMenuItem(<Reports state={state} setState={setState} />)}>Reports</div>}
          {homepermission['Sales Invoices'] && <div className='home_button' onClick={() => handleClickMenuItem(<SalesInvoices state={state} setState={setState} />)}>Sales Invoices</div>}
          {homepermission['Suppliers'] && <div className='home_button' onClick={() => handleClickMenuItem(<Suppliers state={state} setState={setState} />)}>Suppliers ({suppliers.length})</div>}
          {homepermission['Transactions'] && <div className='home_button' onClick={() => handleClickMenuItem(<Transactions state={state} setState={setState} />)}>Transactions ({transactions.length})</div>}
          {homepermission['Settings'] && <div className='home_button' onClick={() => handleClickMenuItem(<Settings state={state} setState={setState} />)}>Settings</div>}
          {(state.user.usertype == 1 || state.user.usertype == 2) && <div className='home_button' onClick={() => handleClickMenuItem(<Users state={state} setState={setState} />)}>Users ({users.length})</div>}
        </div>

        <div className='home_panel'>
          {option}
        </div>

        {
          screenwidth <= smallscreen &&
          <div className="home_floatingbutton" onClick={() => setTogglemenu(!togglemenu)}>
            <span className="home_floatingbuttonicon">+</span>
          </div>
        }
      </div>
    </div>
  );
}

export default Home;