import React, { useState } from 'react';
import Header from './Header.js';

import configData from './Config';
import { Helmet } from 'react-helmet';

import homepage_section1 from './Assets/homepage_section1.jpg';
import homepage_section2 from './Assets/homepage_section2.jpg';
import homepage_section3 from './Assets/homepage_section3.jpg';
import homepage_facebookicon from './Assets/homepage_facebookicon.png';
import homepage_linkedinicon from './Assets/homepage_linkedinicon.png';
import homepage_instagramicon from './Assets/homepage_instagramicon.png';

import './HomePage.css';

function Homepage({ state, setState }) {
    const [planoption, setPlanoption] = useState(1);
    const [paymentoption, setPaymentoption] = useState(1);

    const handleClose = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const handlePlan = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Plan state={state} setState={setState} />, modaltype: 'small', modaltitle: 'Plan' }))
    }

    const handleDemo = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Demo state={state} setState={setState} />, modaltype: 'small', modaltitle: 'Demo' }))
    }

    function Plan() {
        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>We are only accepting Cash Payments at the moment.</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function Demo() {
        const startDemo = () => {
            setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
            setState(state => ({
                ...state,
                user: {
                    userid: '669b5d6facee2',
                    usertoken: '1fdd91bb65550a8b03306c8f1e43b59e',
                    useremail: 'demouser@demo.com',
                    username: 'demo_user',
                    usertype: '0',
                }
            }))
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>Project XYZ 1344 Demo. Press Start Demo to start.</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className='modal_button' onClick={startDemo}>Start Demo</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='homepage'>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="Our software provides powerful cloud-based accounting solutions to help businesses manage finances, track expenses, and generate insightful financial reports." />
                <meta name="keywords" content="accounting software, financial management, cloud accounting, bookkeeping, expense tracking" />
                <meta name="author" content="Accounting Dynamics" />
                <meta property="og:title" content="Cloud-Based Accounting Software" />
                <meta property="og:description" content="Our software provides powerful cloud-based accounting solutions for businesses of all sizes." />
                <meta property="og:image" content={homepage_section1} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Cloud-Based Accounting Software" />
                <meta name="twitter:description" content="Our software provides powerful cloud-based accounting solutions for businesses of all sizes." />
                <meta name="twitter:image" content={homepage_section1} />
                <title>Cloud-Based Accounting Software</title>
            </Helmet>

            <Header state={state} setState={setState} />

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Cloud-based Accounting Software</h1>
                    <p className='homepage_description'>
                        Our software provides powerful cloud-based accounting solutions to help businesses manage finances efficiently.
                        With our platform, you can track expenses, automate bookkeeping, and generate insightful financial reports.
                    </p>
                </div>
                <div className='homepage_sectionright'>
                    <div className='homepage_paymentplans'>
                        <div className="homepage_paymentplan">
                            <h3>Plan</h3>
                            <select className='homepage_paymentplanselect' value={planoption} onChange={(e) => setPlanoption(e.target.value)}>
                                <option value={1}>Basic Plan</option>
                                <option value={2}>Pro Plan</option>
                            </select>
                            <h3>Option</h3>
                            <select className='homepage_paymentplanselect' value={paymentoption} onChange={(e) => setPaymentoption(e.target.value)}>
                                <option value={1}>Monthly</option>
                                <option value={12}>Yearly</option>
                            </select>
                            {planoption == 1 && paymentoption == 1 && <p className="homepage_paymentplanprice">$4/Month</p>}
                            {planoption == 1 && paymentoption == 12 && <p className="homepage_paymentplanprice">$50/Year</p>}
                            {planoption == 2 && paymentoption == 1 && <p className="homepage_paymentplanprice">$10/Month</p>}
                            {planoption == 2 && paymentoption == 12 && <p className="homepage_paymentplanprice">$110/Year</p>}
                            {planoption == 1 &&
                                <ul className='homepage_paymentplanfeatures'>
                                    <li>Limited to 1 Project</li>
                                    <li>Only 1 User Access</li>
                                </ul>
                            }
                            {planoption == 2 &&
                                <ul className='homepage_paymentplanfeatures'>
                                    <li>Multiple Projects</li>
                                    <li>Multi-user Access</li>
                                </ul>
                            }
                            <button className='homepage_planbutton' onClick={handlePlan}>Start Plan</button>
                            <button className='homepage_demobutton' onClick={handleDemo}>Demo Project XYZ</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Optimize Your Finances with Smart Accounting</h1>
                    <p className='homepage_description'>
                        Gain full control over your financial operations with automation, AI-driven insights,
                        and seamless bookkeeping solutions.
                    </p>
                    <h1 className='homepage_title'>Leaders in Cloud Accounting Solutions</h1>
                    <p className='homepage_description'>
                        With years of experience, we provide businesses with smarter, faster,
                        and more efficient financial management tools.
                    </p>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section1} alt="Accounting Software" />
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <img className='homepage_image' src={homepage_section2} alt="Financial Automation" />
                </div>
                <div className='homepage_sectionright'>
                    <h1 className='homepage_title'>Financial Automation</h1>
                    <p className='homepage_description'>Automate bookkeeping, invoicing, and expense tracking.</p>
                    <h1 className='homepage_title'>Real-Time Insights</h1>
                    <p className='homepage_description'>Leverage AI-driven analytics for better financial decisions.</p>
                    <h1 className='homepage_title'>Regulatory Compliance</h1>
                    <p className='homepage_description'>Ensure tax compliance and maintain accurate financial records.</p>
                </div>
            </div>

            <div className='homepage_section'>
                <div className='homepage_sectionleft'>
                    <h1 className='homepage_title'>Industries We Serve</h1>
                    <ul>
                        <li>Small Businesses: Simplify accounting and focus on growth.</li>
                        <li>Enterprises: Gain financial visibility across multiple departments.</li>
                        <li>Freelancers: Manage invoices, expenses, and tax deductions with ease.</li>
                        <li>Nonprofits: Ensure transparency and accurate fund management.</li>
                    </ul>
                </div>
                <div className='homepage_sectionright'>
                    <img className='homepage_image' src={homepage_section3} alt="Industries We Serve" />
                </div>
            </div>

            <div className='homepage_footer'>
                <div className='homepage_footercolumn'>
                    <div className='homepage_footertitle'>Contact Us</div>
                    <div className='homepage_footercontact'>Email: dservicesme@gmail.com</div>
                </div>
                <div className='homepage_footercolumn'>
                    <div className='homepage_socialicon' title="Facebook">
                        <a href={configData.FACEBOOKLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_facebookicon' src={homepage_facebookicon} alt="Facebook Icon" />
                        </a>
                    </div>
                    <div className='homepage_socialicon' title="LinkedIn">
                        <a href={configData.LINKEDINLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_linkedinicon' src={homepage_linkedinicon} alt="LinkedIn Icon" />
                        </a>
                    </div>
                    <div className='homepage_socialicon' title="Instagram">
                        <a href={configData.INSTAGRAMLINK} target="_blank" rel="noopener noreferrer">
                            <img className='homepage_instagramicon' src={homepage_instagramicon} alt="Instagram Icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;