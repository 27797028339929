import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { getPermission } from './Utils/permissions.js';
import Select from './Components/select.js';
import { isValidNumber, hasMoreThanTwoDecimals, parseAmount, convertSpacesToUnderscore } from './Utils/textparse.js';

import Title from './Title';

import './JournalEntries.css';

//20240525

function JournalEntries({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [search, setSearch] = useState('');
    const [filteredjournals, setFilteredjournals] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let filtered = project.journalentries.filter((journal) =>
            journal.name.toLowerCase().includes(search.toLowerCase()) ||
            journal.id.includes(search) ||
            journal.amount.includes(search)
        ).sort((a, b) => b.ts - a.ts);

        setFilteredjournals(filtered);
    }, [project, search]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddJournalEntry />, modaltype: 'large', modaltitle: 'Add Journal Entry' }));
    }

    const editButton = (journal) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditJournalEntry journal={journal} mode='edit' />, modaltype: 'large', modaltitle: 'Edit Journal Entry' }));
    }

    const cloneButton = (journal) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditJournalEntry journal={journal} mode='clone' />, modaltype: 'large', modaltitle: 'Clone Journal Entry' }));
    }

    const viewButton = (journal) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewJournalEntry journal={journal} />, modaltype: 'large', modaltitle: 'View Journal Entry' }));
    }

    const removeButton = (journal) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveJournalEntry journal={journal} />, modaltype: 'small', modaltitle: 'Remove Journal Entry' }));
    }

    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export' }));
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.journalentries || project.journalentries.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.journalentries, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard'))
                .catch(err => setResult('Error copying data to clipboard: ', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');
        const [newEntries, setNewEntries] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!importText) {
                setResult('');
                setNewEntries([]);
                return;
            }

            let isJSON = false;
            let data = [];

            try {
                const jsonData = JSON.parse(importText);
                isJSON = true;
                data = jsonData;
            } catch {
                isJSON = false;
            }

            if (!isJSON) {
                const requiredAttributes = ["Date", "Subaccount", "Name", "Account", "Debit", "Credit"];
                let rows = importText.split('\n').map(row => row.split('\t'));
                const headers = rows.shift().map(header => header.trim());

                const missingHeaders = requiredAttributes.filter(attr => !headers.includes(attr));

                if (headers.length === 0 || missingHeaders.length > 0) {
                    setResult('Invalid headers detected. Missing headers: ' + missingHeaders.join(', '));
                    return;
                }

                if (rows.length === 0) {
                    setResult('No data rows detected.');
                    return;
                }

                data = rows.map(row => {
                    let entry = {};
                    headers.forEach((header, index) => {
                        entry[header] = row[index]?.trim() || '';
                    });
                    return entry;
                });

                const convertedEntries = [];
                let groupedEntries = new Map();

                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    const debitParsed = parseAmount(entry['Debit']);
                    const creditParsed = parseAmount(entry['Credit']);

                    const commonCurrency = debitParsed.currency || creditParsed.currency;
                    let key = convertSpacesToUnderscore(
                        entry['Date'] + " " + entry['Name'] + " " + commonCurrency + " " + entry['Subaccount']
                    );

                    const matchingSubaccount = project.subaccounts.find(subaccount => subaccount.name.toLowerCase() === entry['Subaccount'].toLowerCase());
                    if (!matchingSubaccount) {
                        setResult("Subaccount not found for \"" + entry['Subaccount'] + "\" at row " + i);
                        return
                    }
                    const matchingCurrency = project.currencies.find(currency =>
                        currency.name.toLowerCase() === commonCurrency.toLowerCase() ||
                        currency.symbol.toLowerCase() === commonCurrency.toLowerCase()
                    );
                    if (!matchingCurrency) {
                        setResult("Currency not found for \"" + commonCurrency + "\" at row " + i);
                        break;
                    }

                    if (!groupedEntries.has(key)) {
                        groupedEntries.set(key, {
                            date: entry['Date'],
                            name: entry['Name'],
                            subaccountid: matchingSubaccount.id,
                            currencyid: matchingCurrency.id,
                            rows: [],
                            totaldebit: 0,
                            totalcredit: 0
                        });
                    }

                    let journalEntry = groupedEntries.get(key);

                    let accountType = "";
                    const accountLower = entry['Account'].toLowerCase();
                    let foundAccount = null;

                    if (project.clients.some(client => client.name.toLowerCase() === accountLower)) {
                        accountType = "clients";
                        foundAccount = project.clients.find(client => client.name.toLowerCase() === accountLower);
                    }
                    else if (project.incomeaccounts.some(acc => acc.name.toLowerCase() === accountLower)) {
                        accountType = "incomeaccounts";
                        foundAccount = project.incomeaccounts.find(acc => acc.name.toLowerCase() === accountLower);
                    }
                    else if (project.expenseaccounts.some(acc => acc.name.toLowerCase() === accountLower)) {
                        accountType = "expenseaccounts";
                        foundAccount = project.expenseaccounts.find(acc => acc.name.toLowerCase() === accountLower);
                    }
                    else if (project.suppliers.some(supplier => supplier.name.toLowerCase() === accountLower)) {
                        accountType = "suppliers";
                        foundAccount = project.suppliers.find(supplier => supplier.name.toLowerCase() === accountLower);
                    }
                    else {
                        setResult('Account not found: ' + entry['Account'] + ' in row: ' + i);
                        return;
                    }

                    if (foundAccount) {
                        journalEntry.rows.push({
                            accounttype: accountType,
                            accountid: foundAccount.id,
                            name: '',
                            debit: debitParsed.amount,
                            credit: creditParsed.amount,
                        });
                        journalEntry.totaldebit += debitParsed.amount;
                        journalEntry.totalcredit += creditParsed.amount;
                    }
                }

                groupedEntries.forEach((entry, key) => {
                    const journalEntry = {
                        ts: Math.floor(new Date(entry.date).getTime() / 1000),
                        subaccountid: entry.subaccountid,
                        currencyid: entry.currencyid,
                        name: entry.name,
                        rows: entry.rows,
                        amount: entry.totaldebit
                    };

                    convertedEntries.push(journalEntry);
                });

                console.log(convertedEntries);

                if (convertedEntries.length > 0) {
                    setNewEntries(convertedEntries);
                    setResult('Data is valid. Entries found: ' + convertedEntries.length);
                } else {
                    setResult('No journal entries detected.');
                }
            }
        }, [importText]);

        const handleImport = () => {
            console.log('Trying to import data');

            if (newEntries.length == 0) {
                setResult('No new entries to import');
                return;
            }

            setLoading(true);
            setResult(null);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                journalentries: newEntries
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTJOURNALENTRIES, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'IMPORT_JOURNALENTRIES',
                            journalentries: newEntries
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });

            setResult('Data imported successfully.');
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function NewRow({ row, index, handleTypeChange, handleAccountChange, handleNameChange, handleDebitChange, handleCreditChange, handleRemoveRow }) {
        const renderAccountOptions = () => {
            switch (row.accounttype) {
                case 'incomeaccounts':
                    return <Select data={project.incomeaccounts} itemid={row.accountid} setItemid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} />;
                case 'expenseaccounts':
                    return <Select data={project.expenseaccounts} itemid={row.accountid} setItemid={(accountId) => handleAccountChange({ target: { value: accountId } }, index)} />;
                case 'clients':
                    return <Select data={project.clients} itemid={row.accountid} setItemid={(clientId) => handleAccountChange({ target: { value: clientId } }, index)} />;
                case 'suppliers':
                    return <Select data={project.suppliers} itemid={row.accountid} setItemid={(supplierId) => handleAccountChange({ target: { value: supplierId } }, index)} />;
                default:
                    return null;
            }
        };

        return (
            <tr>
                <td>
                    <select
                        className='modal_select'
                        value={row.accounttype}
                        onChange={(e) => handleTypeChange(e, index)}
                    >
                        <option value="incomeaccounts">Income Accounts</option>
                        <option value="expenseaccounts">Expense Accounts</option>
                        <option value="clients">Accounts Receivable</option>
                        <option value="suppliers">Accounts Payable</option>
                    </select>
                </td>
                <td>
                    {renderAccountOptions()}
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        value={row.name}
                        onChange={(e) => handleNameChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder={0}
                        value={row.debit}
                        onChange={(e) => handleDebitChange(e, index)}
                    />
                </td>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder={0}
                        value={row.credit}
                        onChange={(e) => handleCreditChange(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveRow(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddJournalEntry() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [rows, setRows] = useState([{ accounttype: 'incomeaccounts', accountid: '', name: '', debit: 0, credit: 0 }]);
        const [name, setName] = useState('');
        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [currencyid, setCurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        const handleTypeChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accounttype = selectedRow;
            setRows(updatedRows);
        };

        const handleAccountChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accountid = selectedRow;
            setRows(updatedRows);
        };

        const handleNameChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].name = selectedRow;
            setRows(updatedRows);
        };

        const handleDebitChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].debit = selectedRow;
            setRows(updatedRows);
        };

        const handleCreditChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].credit = selectedRow;
            setRows(updatedRows);
        };

        const handleAddRow = () => {
            const lastRowAccountType = rows.length > 0 ? rows[rows.length - 1].accounttype : 'incomeaccounts';
            setRows([...rows, { accounttype: lastRowAccountType, accountid: '', name: '', debit: 0, credit: 0 }])
        };

        const handleRemoveRow = (indexToRemove) => {
            if (rows.length > 1) {
                const updatedRows = rows.filter((_, index) => index !== indexToRemove);
                setRows(updatedRows);
            }
        };

        let sumDebit = rows.reduce((sum, item) => sum + (Number(item.debit) || 0), 0);
        let sumCredit = rows.reduce((sum, item) => sum + (Number(item.credit) || 0), 0);
        if (hasMoreThanTwoDecimals(sumDebit)) {
            sumDebit = sumDebit.toFixed(2);
        }

        if (hasMoreThanTwoDecimals(sumCredit)) {
            sumCredit = sumCredit.toFixed(2);
        }
        const totalDifference = (sumDebit - sumCredit).toFixed(2);
        const currencySymbol = project.currencies.find(item => item.id === currencyid)?.symbol;

        const addJournal = (timestamp, subaccountid, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Journal Entries',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!subaccountid || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                subaccountid: subaccountid,
                currencyid: currencyid,
                amount: sumdebit,
                name: trimmedname,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.ADDJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_JOURNALENTRY',
                            journalentry: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead><tr><th>Type</th><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th><th></th></tr></thead>
                                <tbody>
                                    {
                                        rows.map((row, index) => (
                                            <NewRow
                                                key={'row' + index}
                                                row={row}
                                                index={index}
                                                handleTypeChange={handleTypeChange}
                                                handleAccountChange={handleAccountChange}
                                                handleNameChange={handleNameChange}
                                                handleDebitChange={handleDebitChange}
                                                handleCreditChange={handleCreditChange}
                                                handleRemoveRow={handleRemoveRow}
                                            />
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{totalDifference != 0 && totalDifference}</div></td>
                                        <td><div>{currencySymbol} {sumDebit.toLocaleString('en-US')}</div></td>
                                        <td><div>{currencySymbol} {sumCredit.toLocaleString('en-US')}</div></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className='modal_button' onClick={handleAddRow}>Add row</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addJournal(timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditJournalEntry({ journal, mode }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimstamp] = useState(0);

        const [selectedsubaccount, setSelectedSubaccount] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [name, setName] = useState('');
        const [rows, setRows] = useState([{ accounttype: 'incomeaccounts', accountid: '', name: '', debit: 0, credit: 0 }]);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimstamp(unixTimestamp);
        }, [selecteddate]);

        useEffect(() => {
            if (journal && Array.isArray(journal.rows)) {
                const journalCopy = {
                    ...journal,
                    rows: journal.rows.map(row => ({ ...row }))
                };
                setId(journalCopy.id);
                setSelecteddate(new Date(journalCopy.ts * 1000));
                setTimstamp(journalCopy.ts);
                setCurrencyid(journalCopy.currencyid);
                setSelectedSubaccount(journalCopy.subaccountid);
                setName(journalCopy.name);
                setRows(journalCopy.rows);
            }
        }, [journal]);

        const handleTypeChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accounttype = selectedRow;
            setRows(updatedRows);
        };

        const handleAccountChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].accountid = selectedRow;
            setRows(updatedRows);
        };

        const handleNameChange = (event, index) => {
            const selectedRow = event.target.value;
            const updatedRows = [...rows];
            updatedRows[index].name = selectedRow;
            setRows(updatedRows);
        };

        const handleDebitChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].debit = selectedRow;
            setRows(updatedRows);
        };

        const handleCreditChange = (event, index) => {
            const selectedRow = isValidNumber(event.target.value) ? event.target.value : 0;
            const updatedRows = [...rows];
            updatedRows[index].credit = selectedRow;
            setRows(updatedRows);
        };

        const handleAddRow = () => {
            const lastRowAccountType = rows.length > 0 ? rows[rows.length - 1].accounttype : 'incomeaccounts';
            setRows([...rows, { accounttype: lastRowAccountType, accountid: '', name: '', debit: 0, credit: 0 }])
        };

        const handleRemoveRow = (indexToRemove) => {
            if (rows.length > 1) {
                const updatedRows = rows.filter((_, index) => index !== indexToRemove);
                setRows(updatedRows);
            }
        };

        let sumDebit = rows.reduce((sum, item) => sum + (Number(item.debit) || 0), 0);
        let sumCredit = rows.reduce((sum, item) => sum + (Number(item.credit) || 0), 0);
        if (hasMoreThanTwoDecimals(sumDebit)) {
            sumDebit = parseFloat(sumDebit.toFixed(2));
        }

        if (hasMoreThanTwoDecimals(sumCredit)) {
            sumCredit = parseFloat(sumCredit.toFixed(2));
        }
        const totalDifference = (sumDebit - sumCredit).toFixed(2);
        const currencySymbol = project.currencies.find(item => item.id === currencyid)?.symbol;

        const addJournal = (timestamp, subaccountid, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Journal Entries',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!subaccountid || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                subaccountid: subaccountid,
                currencyid: currencyid,
                amount: sumdebit,
                name: trimmedname,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.ADDJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_JOURNALENTRY',
                            journalentry: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        const updateJournal = (id, timestamp, subaccountid, currencyid, name, sumdebit, sumcredit, rows, setResult) => {

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Journal Entries',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!subaccountid || !name || !currencyid) {
                setResult('Missing info')
                return
            }

            if (sumdebit !== sumcredit) {
                setResult('Entries not equal')
                return
            }

            setLoading(true);

            const trimmedname = name.trim();

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                subaccountid: subaccountid,
                currencyid: currencyid,
                name: trimmedname,
                amount: sumdebit,
                rows: rows
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_JOURNALENTRY',
                            journalentry: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.subaccounts} itemid={selectedsubaccount} setItemid={setSelectedSubaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Currency</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={currencyid} setItemid={setCurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead><tr><th>Type</th><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th><th></th></tr></thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <NewRow
                                            key={'row' + index}
                                            row={row}
                                            index={index}
                                            handleTypeChange={handleTypeChange}
                                            handleAccountChange={handleAccountChange}
                                            handleNameChange={handleNameChange}
                                            handleDebitChange={handleDebitChange}
                                            handleCreditChange={handleCreditChange}
                                            handleRemoveRow={handleRemoveRow}
                                        />
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><div>Total</div></td>
                                        <td><div></div></td>
                                        <td><div>{totalDifference != 0 && totalDifference}</div></td>
                                        <td><div>{currencySymbol} {sumDebit.toLocaleString('en-US')}</div></td>
                                        <td><div>{currencySymbol} {sumCredit.toLocaleString('en-US')}</div></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='modal_actions'>
                        <div className='modal_buttons'>
                            <div className='modal_buttoncontainer'>
                                <button className='modal_button' onClick={handleAddRow}>Add row</button>
                            </div>
                            <div className='modal_buttoncontainer'></div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            {mode === 'edit' && <button className="modal_button" onClick={() => updateJournal(id, timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>}
                            {mode === 'clone' && <button className="modal_button" onClick={() => addJournal(timestamp, selectedsubaccount, currencyid, name, sumDebit, sumCredit, rows, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>}
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(journal)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveJournalEntry({ journal }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(journal.id)
        }, [journal]);

        const removeJournal = (id) => {
            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Journal Entries',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEJOURNALENTRY, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_JOURNALENTRY',
                            id: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeJournal(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewJournalEntry({ journal }) {
        const printRef = useRef();

        const [id, setId] = useState('');
        const [timestamp, setTimstamp] = useState(0);

        const [rows, setRows] = useState([]);
        const [name, setName] = useState('');
        const [currencyid, setCurrencyid] = useState('');
        const [subaccount, setSubaccount] = useState('');

        useEffect(() => {
            setId(journal.id);
            setName(journal.name);
            setRows(journal.rows);
            setTimstamp(journal.ts);
            setCurrencyid(journal.currencyid);
            const sub = project.subaccounts.find(sub => sub.id === journal.subaccountid)?.name;
            setSubaccount(sub)
        }, [journal]);

        const currency = project.currencies.find(item => item.id === currencyid, 10)?.symbol || '';

        const renderAccountName = (accounttype, accountid) => {
            switch (accounttype) {
                case 'incomeaccounts':
                    return project.incomeaccounts.find(account => account.id == accountid)?.name || 'Account not found'
                case 'expenseaccounts':
                    return project.expenseaccounts.find(account => account.id == accountid)?.name || 'Account not found'
                case 'clients':
                    return project.clients.find(client => client.id == accountid)?.name || 'Client not found'
                case 'suppliers':
                    return project.suppliers.find(supplier => supplier.id == accountid)?.name || 'Supplier not found'
                default:
                    return null;
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(timestamp).format('D MMMM YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Subaccount</div>
                        </div>
                        <div className='modal_rowsection'>{subaccount}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Name</div>
                        </div>
                        <div className='modal_rowsection'>{name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead><tr><th>Account</th><th>Name</th><th>Debit</th><th>Credit</th></tr></thead>
                                <tbody>
                                    {
                                        rows.map((row, index) => {
                                            const debit = parseFloat(row.debit).toLocaleString('en-US');
                                            const credit = parseFloat(row.credit).toLocaleString('en-US');

                                            return (
                                                <tr key={'row' + index}>
                                                    <td>
                                                        <div>{renderAccountName(row.accounttype, row.accountid)}</div>
                                                    </td>
                                                    <td><div>{row.name}</div></td>
                                                    <td><div>{currency} {debit.toLocaleString('en-US')}</div></td>
                                                    <td><div>{currency} {credit.toLocaleString('en-US')}</div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td>{currency} {rows.reduce((sum, item) => sum + Number(item.debit), 0).toLocaleString('en-US')}</td>
                                        <td>{currency} {rows.reduce((sum, item) => sum + Number(item.credit), 0).toLocaleString('en-US')}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => cloneButton(journal)}>Clone</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='journalentries'>
            <Title text='Journal Entries' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Journal Entry</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>ID</th><th>Name</th><th>Amount</th></tr></thead>
                <tbody>
                    {
                        filteredjournals.map((journal, index) => {
                            const currency = project.currencies.find(item => item.id === journal.currencyid, 10)?.symbol || '';
                            const amount = parseFloat(journal.amount).toLocaleString('en-US');

                            return (
                                <tr key={'journal' + index}>
                                    <td><div className='table_button' onClick={() => editButton(journal)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(journal)}>View</div></td>
                                    <td><div>{moment.unix(journal.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{journal.id}</div></td>
                                    <td><div>{journal.name}</div></td>
                                    <td><div>{currency + ' ' + amount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default JournalEntries;