import { getIncomeAccounts, getExpenseAccounts } from './chartaccounts.js';
import { getCashAccounts } from './cash.js';
import { getClientAccounts } from './clients.js';
import { getSupplierAccounts } from './suppliers.js';

export function getClientReceivables(clients, transactions, journalentries, exchangerates, currencies, basecurrency) {
    const data = getClientAccounts(clients, transactions, journalentries, exchangerates, currencies, basecurrency).filter((entry) => entry.balance > 0);
    return data;
}

export function getCashSummary(cashaccounts, transactions, interaccounttransfers, currencies, exchangerates) { //20240902
    const data = getCashAccounts(cashaccounts, transactions, interaccounttransfers, currencies, exchangerates);
    return data;
}

export function getIncomeSummary(incomegroups, incomeaccounts, journalentries, exchangerates, currencies) { //20240108
    const data = getIncomeAccounts(incomegroups, incomeaccounts, journalentries, exchangerates, currencies);
    return data;
}

export function getExpenseSummary(expensegroups, expenseaccounts, transactions, journalentries, exchangerates, currencies) { //20240108
    const data = getExpenseAccounts(expensegroups, expenseaccounts, transactions, journalentries, exchangerates, currencies);
    return data;
}

export function getLiabilitySummary(suppliers, transactions, purchaseinvoices, journalentries, exchangerates, currencies, basecurrency) { //20240108
    const data = getSupplierAccounts(suppliers, transactions, purchaseinvoices, journalentries, exchangerates, currencies, basecurrency).filter((entry) => entry.balance !== 0);
    return data;
}