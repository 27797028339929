import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import axios from 'axios';
import configData from './Config';

import Select from './Components/select.js';
import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';

import './Reconciliations.css';

//20240524

function Reconciliations({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [reconciliations, setReconciliations] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setReconciliations(project.reconciliations)
    }, [project]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddReconciliation />, modaltype: 'small', modaltitle: 'Add Recounciliation' }));
    }

    const editButton = (reconciliation) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditReconciliation reconciliation={reconciliation} />, modaltype: 'small', modaltitle: 'Edit Recounciliation' }));
    }

    const removeButton = (reconciliation) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveReconciliation reconciliation={reconciliation} />, modaltype: 'small', modaltitle: 'Remove Recounciliation' }));
    }

    const viewButton = (reconciliation) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewReconciliation reconciliation={reconciliation} />, modaltype: 'small', modaltitle: 'View Recounciliation' }));
    }

    function AddReconciliation() {
        const [date, setDate] = useState(new Date());
        const [ts, setTs] = useState(0);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [balance, setBalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setTs(Math.floor(new Date(date).getTime() / 1000));
        }, [date]);

        const addReconciliation = (ts, selectedaccount, balance) => {
            console.log('Trying to add reconciliation');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Reconciliations',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: ts,
                cashaccountid: selectedaccount,
                balance: balance
            }

            axios.post(configData.CONTROLLERURL + configData.ADDRECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_RECONCILIATION',
                            reconciliation: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={date}
                                onChange={(date) => setDate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <div className='modal_label'>Balance</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="0"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                            />
                        </div>
                    </div>
                </div >
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addReconciliation(ts, selectedaccount, balance)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditReconciliation({ reconciliation }) {
        const [id, setId] = useState('');

        const [date, setDate] = useState('');
        const [ts, setTs] = useState(0);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [balance, setBalance] = useState(0);

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reconciliation.id)
            setTs(reconciliation.ts);
            setDate(new Date(reconciliation.ts * 1000));
            setSelectedaccount(reconciliation.cashaccountid)
            setBalance(reconciliation.balance);
        }, [reconciliation]);

        useEffect(() => {
            setTs(Math.floor(new Date(date).getTime() / 1000));
        }, [date]);

        const updateReconciliation = (id, ts, selectedaccount, balance) => {
            console.log('Trying to update reconciliation');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Reconciliations',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: ts,
                cashaccountid: selectedaccount,
                balance: balance
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATERECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_RECONCILIATION',
                            reconciliation: data
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={date}
                                onChange={(date) => setDate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <Select data={project.cashaccounts} itemid={selectedaccount} setItemid={setSelectedaccount} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='rowsection'>
                            <div className='modal_label'>Balance</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="0"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateReconciliation(id, ts, selectedaccount, balance)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(reconciliation)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveReconciliation({ reconciliation }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(reconciliation.id)
        }, [reconciliation]);

        const removeReconciliation = (id) => {
            console.log('Trying to remove reconciliation');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Reconciliations',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVERECONCILIATION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove reconciliation data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_RECONCILIATION',
                            reconciliationid: id
                        });
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeReconciliation(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const ViewReconciliation = ({ reconciliation }) => {
        const [id, setId] = useState('');

        const [date, setDate] = useState('');
        const [ts, setTs] = useState(0);
        const [selectedaccount, setSelectedaccount] = useState('');
        const [accountname, setAccountname] = useState('');

        const [balancecurrency, setBalancecurrency] = useState('');
        const [balance, setBalance] = useState(0);

        const printRef = useRef();

        useEffect(() => {
            setId(reconciliation.id)
            setTs(reconciliation.ts);
            setDate(new Date(reconciliation.ts * 1000));
            setSelectedaccount(reconciliation.cashaccountid);
            let cashaccount = project.cashaccounts.find(account => account.id === reconciliation.cashaccountid);
            setAccountname(cashaccount?.name || 'Account not found');
            setBalance(Number(reconciliation.balance).toLocaleString('en-US'));
            let cashaccountCurrencyid = cashaccount?.currencyid || null;
            let currencySymbol = project.currencies.find(item => item.id === cashaccountCurrencyid)?.symbol || '';
            setBalancecurrency(currencySymbol);
        }, [reconciliation]);

        useEffect(() => {
            setTs(Math.floor(new Date(date).getTime() / 1000));
        }, [date]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                        <div className='modal_rowsection'>{project.name}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>ID</div>
                        </div>
                        <div className='modal_rowsection'>{id}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>{moment.unix(ts).format('D MMMM YYYY')}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Cash Account</div>
                        </div>
                        <div className='modal_rowsection'>{accountname}</div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Balance</div>
                        </div>
                        <div className='modal_rowsection'>{balancecurrency} {balance}</div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='reconciliation'>
            <Title text='Reconciliation' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Reconciliation</button>
                </div>
            </div>

            <table className='table'>
                <thead><tr><th></th><th></th><th>Date</th><th>Cash Account</th><th>Balance</th></tr></thead>
                <tbody>
                    {
                        reconciliations.sort((a, b) => b.ts - a.ts).map((reconciliation, index) => {
                            let cashaccount = project.cashaccounts.find(account => account.id === reconciliation.cashaccountid);
                            let cashaccountName = cashaccount?.name || 'Account not found';
                            let cashaccountCurrencyid = cashaccount?.currencyid || null;
                            let date = moment.unix(reconciliation.ts).format('D/MMM/YYYY');
                            let currencySymbol = project.currencies.find(item => item.id === cashaccountCurrencyid)?.symbol || '';

                            return (
                                <tr key={'reconciliation' + index}>
                                    <td><div className='table_button' onClick={() => editButton(reconciliation)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(reconciliation)}>View</div></td>
                                    <td><div>{date}</div></td>
                                    <td><div>{cashaccountName}</div></td>
                                    <td><div>{currencySymbol} {Number(reconciliation.balance).toLocaleString('en-US')}</div></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Reconciliations;