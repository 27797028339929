import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { getPermission, permissionResources } from './Utils/permissions.js';

import Title from './Title';

import './Users.css';

function Users({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setUsers(project.users)
    }, [project]);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddUser />, modaltype: 'large', modaltitle: 'Add User' }));
    }

    const editButton = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditUser user={user} />, modaltype: 'large', modaltitle: 'Edit User' }));
    }

    const viewButton = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewUser user={user} />, modaltype: 'large', modaltitle: 'View User' }));
    }

    const viewUsersStatus = (users) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewUsersStatus users={users} />, modaltype: 'large', modaltitle: 'View Users Status' }));
    }

    const removeButton = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveUser user={user} />, modaltype: 'small', modaltitle: 'Remove User' }));
    }


    const exportPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ExportPage />, modaltype: 'small', modaltitle: 'Export Users' }));
    }

    function ExportPage() {
        const [result, setResult] = useState('');

        useEffect(() => {
            if (!project || !project.users || project.users.length === 0) {
                setResult('No data to export');
                return;
            }

            const importedContent = JSON.stringify(project.users, null, 2);

            navigator.clipboard.writeText(importedContent)
                .then(() => setResult('Data copied to clipboard.'))
                .catch(err => setResult('Error copying data to clipboard:', err));
        }, []);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {result}
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const importPage = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ImportPage />, modaltype: 'small', modaltitle: 'Import Users' }));
    }

    function ImportPage() {
        const [importText, setImportText] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        const handleImport = async () => {
            console.log('Trying to import users');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Users',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const importedUsers = JSON.parse(importText);
            if (!(importedUsers instanceof Array)) {
                setResult(false);
                setResult('Imported data is not in the expected format');
                return;
            }

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                users: importedUsers
            }

            axios.post(configData.CONTROLLERURL + configData.IMPORTUSERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Import data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setResult(res.data.data);
                        handleCloseModal();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            }).finally(() => {
                setLoading(false)
            })
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <textarea
                                className='modal_textarea'
                                value={importText}
                                onChange={e => setImportText(e.target.value)}
                                placeholder="Paste your import data here..."
                                rows={10}
                                cols={50}
                                style={{ resize: 'vertical' }}
                            />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleImport} disabled={loading}>{loading ? 'Loading...' : 'Import'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const addUser = (email, permissions, devices, setLoading, setResult) => {
        console.log('Trying to add user');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Users',
            'add',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        setLoading(true);

        const userExists = project.users.some(user => user.email === email);
        const deviceExists = project.users.some(user => user.devices.some(device => device.email === email));
        if (userExists || deviceExists) {
            setLoading(false);
            setResult('Email already exists ' + email);
            return;
        }

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            email: email,
            permissions: permissions,
            devices: devices
        }

        axios.post(configData.CONTROLLERURL + configData.ADDUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Add user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    data.id = res.data.id;
                    dispatch({
                        type: 'ADD_USER',
                        user: data
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false)
        })
    }

    const updateUser = (id, email, permissions, devices, setLoading, setResult) => {
        console.log('Trying to update user permissions');

        setResult('');

        const hasPermission = getPermission(
            project.projectuserid,
            project.users,
            state.user.userid,
            'Users',
            'update',
            project.archived
        );
        if (hasPermission.code == 0) {
            setResult(hasPermission.data);
            return;
        }

        setLoading(true);

        const emailExistsElsewhere = project.users.some(user => (user.email === email || user.devices.some(device => devices.some(newDevice => newDevice.email === device.email))) && user.id !== id);
        if (emailExistsElsewhere) {
            setLoading(false);
            setResult('Email already exists in another user or device');
            return;
        }

        const connectedDeviceRemoved = devices.find(device => !devices.some(newDevice => newDevice.email === device.email) && device.id);
        if (connectedDeviceRemoved) {
            setLoading(false);
            setResult('Cannot remove connected devices');
            return;
        }

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            id: id,
            email: email,
            permissions: permissions,
            devices: devices
        }

        axios.post(configData.CONTROLLERURL + configData.UPDATEUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Update user permission data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    dispatch({
                        type: 'UPDATE_USER',
                        user: data
                    });
                    handleCloseModal();
                }
                else {
                    setResult(res.data.data);
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false)
        })
    }


    const connectUser = (email, setLoading, setResult, refresh, setRefresh) => {
        console.log('Trying to connect user');

        if (!email) {
            setResult('Missing info');
            return
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            email: email,
        }

        axios.post(configData.CONTROLLERURL + configData.CONNECTUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Connect user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success')
                    setRefresh(!refresh)
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error');
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
            setLoading(false);
        });
    }

    const disconnectUser = (email, setLoading, setResult, refresh, setRefresh) => {
        console.log('Trying to disconnect user');

        if (!email) {
            return { code: 0, data: "No email" };
        }

        setLoading(true);

        const data = {
            projectuserid: project.projectuserid,
            projectid: project.projectid,
            email: email,
        }

        axios.post(configData.CONTROLLERURL + configData.DISCONNECTUSER, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            }
        }).then((res) => {
            console.log('Disconnect user data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setResult('Success');
                    setRefresh(!refresh)
                }
                else {
                    setResult(res.data.data)
                }
            }
            else {
                setResult('Error')
            }
        }).catch((err) => {
            setResult(err.response?.data?.message || err.message || 'Network error')
        }).finally(() => {
            setLoading(false);
        });
    }

    function PermissionRow({ resource, permissions, handleChange }) {
        return (
            <tr>
                <td><div>{resource}</div></td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="open"
                            checked={permissions?.open}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="add"
                            checked={permissions?.add}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="update"
                            checked={permissions?.update}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
                <td>
                    <div>
                        <input
                            type="checkbox"
                            name="remove"
                            checked={permissions?.remove}
                            onChange={(e) => handleChange(resource, e)}
                        />
                    </div>
                </td>
            </tr>
        );
    }

    function NewDevice({ row, index, handleEmailChangeDevice, handleRemoveDevice }) {
        return (
            <tr>
                <td>
                    <input
                        className='modal_input'
                        type="text"
                        placeholder='Email'
                        value={row.email}
                        onChange={(e) => handleEmailChangeDevice(e, index)}
                    />
                </td>
                <td>
                    <div className='modal_removeitembutton' onClick={() => handleRemoveDevice(index)}>&minus;</div>
                </td>
            </tr>
        );
    }

    function AddUser() {
        const [email, setEmail] = useState('');

        const [selectAllColumns, setSelectAllColumns] = useState({ open: false, add: false, update: false, remove: false });
        const [permissions, setPermissions] = useState({});
        const [devices, setDevices] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        const handleAddDevice = () => {
            setResult(null)
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const handleChange = (resource, event) => {
            const { name, checked } = event.target;
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [resource]: {
                    ...prevPermissions[resource],
                    [name]: checked
                }
            }));
        };

        const handleSelectColumnAll = (permissionResources, columnName) => {
            setPermissions(prevPermissions => {
                const updatedPermissions = { ...prevPermissions };
                let allChecked = true;

                for (const resource of permissionResources) {
                    if (!prevPermissions[resource]?.[columnName]) {
                        allChecked = false;
                        break;
                    }
                }

                for (const resource of permissionResources) {
                    updatedPermissions[resource] = {
                        ...updatedPermissions[resource],
                        [columnName]: !allChecked
                    };
                }

                return updatedPermissions;
            });
        };

        const handleAddUser = (email, permissions, devices, setLoading, setResult) => {
            addUser(email, permissions, devices, setLoading, setResult);
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>User Email</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Permissions</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Section</th>
                                        <th>
                                            <label className="modal_label">
                                                Open
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['open']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'open')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Add
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['add']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'add')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Update
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['update']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'update')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Remove
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['remove']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'remove')}
                                                />
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        permissionResources.map((resource, index) => (
                                            <PermissionRow
                                                key={index}
                                                resource={resource}
                                                permissions={permissions[resource]}
                                                handleChange={handleChange}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Devices</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length ?
                                            devices.map((row, index) => (
                                                <NewDevice
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleEmailChangeDevice={handleEmailChangeDevice}
                                                    handleRemoveDevice={handleRemoveDevice}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={2}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => handleAddUser(email, permissions, devices, setLoading, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Save'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');

        const [selectAllColumns, setSelectAllColumns] = useState({ open: false, add: false, update: false, remove: false });
        const [permissions, setPermissions] = useState([]);
        const [devices, setDevices] = useState([]);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            if (user) {
                const userCopy = JSON.parse(JSON.stringify(user));

                setId(userCopy.id);
                setEmail(userCopy.email);
                setPermissions(userCopy.permissions);
                setDevices(userCopy.devices);
            }
        }, [user]);

        const handleAddDevice = () => {
            setResult(null)
            setDevices([...devices, { email: '' }]);
        };

        const handleEmailChangeDevice = (e, index) => {
            const deviceToRemove = devices[index];
            if (deviceToRemove.id) {
                setResult('Cannot change connected devices');
                return;
            }

            const newDevices = [...devices];
            newDevices[index].email = e.target.value;
            setDevices(newDevices);
        };

        const handleRemoveDevice = (index) => {
            const deviceToRemove = devices[index];
            if (deviceToRemove.id) {
                setResult('Cannot remove connected devices');
                return;
            }

            const newDevices = [...devices];
            newDevices.splice(index, 1);
            setDevices(newDevices);
        };

        const handleChange = (resource, event) => {
            const { name, checked } = event.target;
            setPermissions(prevPermissions => ({
                ...prevPermissions,
                [resource]: {
                    ...prevPermissions[resource],
                    [name]: checked
                }
            }));
        };

        const handleSelectColumnAll = (permissionResources, columnName) => {
            setPermissions(prevPermissions => {
                const updatedPermissions = { ...prevPermissions };
                let allChecked = true;

                for (const resource of permissionResources) {
                    if (!prevPermissions[resource]?.[columnName]) {
                        allChecked = false;
                        break;
                    }
                }

                for (const resource of permissionResources) {
                    updatedPermissions[resource] = {
                        ...updatedPermissions[resource],
                        [columnName]: !allChecked
                    };
                }

                return updatedPermissions;
            });
        };

        const handleUpdateUser = (id, email, permissions, devices, setLoading, setResult) => {
            updateUser(id, email, permissions, devices, setLoading, setResult);
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>User ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {id}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>User Email</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {email}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Permissions</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Section</th>
                                        <th>
                                            <label className="modal_label">
                                                Open
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['open']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'open')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Add
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['add']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'add')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Update
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['update']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'update')}
                                                />
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Remove
                                                <input
                                                    type="checkbox"
                                                    checked={selectAllColumns['remove']}
                                                    onChange={() => handleSelectColumnAll(permissionResources, 'remove')}
                                                />
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        permissionResources.map((resource, index) => (
                                            <PermissionRow
                                                key={index}
                                                resource={resource}
                                                permissions={permissions[resource]}
                                                handleChange={handleChange}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Devices</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        devices.length ?
                                            devices.map((row, index) => (
                                                <NewDevice
                                                    key={index}
                                                    row={row}
                                                    index={index}
                                                    handleEmailChangeDevice={handleEmailChangeDevice}
                                                    handleRemoveDevice={handleRemoveDevice}
                                                />
                                            ))
                                            :
                                            <tr><td colSpan={2}>No devices</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="modal_button" onClick={handleAddDevice}>Add Device</button>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => handleUpdateUser(id, email, permissions, devices, setLoading, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(user)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [devices, setDevices] = useState([]);

        const [permissions, setPermissions] = useState({});

        const printRef = useRef();

        useEffect(() => {
            setId(user.id)
            setEmail(user.email)
            setPermissions(user.permissions)
            setDevices(user.devices)
        }, [user]);

        return (
            <div className='modal_body'>
                <div className='modal_printable' ref={printRef}>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Project Name</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {project.name}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>User ID</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {id}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>User Email</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {email}
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Permissions</div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Section</th>
                                        <th>
                                            <label className="modal_label">
                                                Open
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Add
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Update
                                            </label>
                                        </th>
                                        <th>
                                            <label className="modal_label">
                                                Remove
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        permissionResources.map((resource, index) => {
                                            const resourcePermissions = permissions[resource] || {};

                                            return (
                                                <tr key={'resource' + index}>
                                                    <td>{resource}</td>
                                                    <td>{resourcePermissions.open ? 'yes' : 'no'}</td>
                                                    <td>{resourcePermissions.add ? 'yes' : 'no'}</td>
                                                    <td>{resourcePermissions.update ? 'yes' : 'no'}</td>
                                                    <td>{resourcePermissions.remove ? 'yes' : 'no'}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Devices</div>
                        </div>
                    </div>
                    <table className='modal_table'>
                        <thead>
                            <tr>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                devices.length ?
                                    devices.map((device, index) => {
                                        return (
                                            <tr key={'device' + index}>
                                                <td>{device.email}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr><td colSpan={4}>No devices</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <ReactToPrint
                                trigger={() => (<button className="modal_button">Print</button>)}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUsersStatus({ users }) {
        const [usersupdated, setUsersupdated] = useState([]);
        const [refresh, setRefresh] = useState(false);

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState('');

        useEffect(() => {
            const allemails = users.flatMap(user => [{ id: user.id, type: 'user', email: user.email }, ...user.devices.map(device => ({ id: device.id, type: 'device', email: device.email }))]).filter(({ email }) => email);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                emails: allemails.map(({ email }) => email)
            }
            console.log(data)
            axios.post(configData.CONTROLLERURL + configData.GETUSERSSTATUS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Users status data received')
                console.log(res.data)
                if (res.data instanceof Array) {
                    const updatedUsers = users.flatMap(user => {
                        const userStatus = res.data.find(status => status.email === user.email);
                        const updatedUser = {
                            ...user,
                            ...userStatus,
                            type: 'user'
                        };

                        const updatedDevices = user.devices.map(device => {
                            const deviceStatus = res.data.find(status => status.email === device.email);
                            return {
                                ...device,
                                ...deviceStatus,
                                type: 'device'
                            };
                        });

                        return [updatedUser, ...updatedDevices];
                    });

                    setUsersupdated(updatedUsers);
                }
            })
        }, [refresh]);

        const getStatus = (email) => {
            const status = usersupdated.find(item => item.email === email) || {};
            const { registered, connected, lastlogin } = status;

            if (!registered) return 'Not registered';
            if (!connected) return 'Not connected'
            if (!lastlogin) return 'No last login';
            if (lastlogin) return moment.unix(lastlogin).format('dddd, Do MMMM YYYY, h:mm:ss A');
        };

        const renderActionButton = (email) => {
            const status = usersupdated.find(item => item.email === email) || {};

            if (status.registered & !status.connected) {
                return (
                    <button className="users_actionbutton" onClick={() => connectUser(email, setLoading, setResult, refresh, setRefresh)}>
                        Connect user
                    </button>
                );
            }

            if (status.connected) {
                return (
                    <button className="users_actionbutton" onClick={() => disconnectUser(email, setLoading, setResult, refresh, setRefresh)}>
                        Disconnect user
                    </button>
                );
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <table className='modal_table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        usersupdated.map((user, index) => (
                                            <tr key={'user' + index}>
                                                <td>{user.id}</td>
                                                <td>{user.type}</td>
                                                <td>{user.email}</td>
                                                <td>{getStatus(user.email)}</td>
                                                <td>{renderActionButton(user.email)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function RemoveUser({ user }) {
        const [id, setId] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            setId(user.id)
        }, [user]);

        const removeUser = async () => {
            console.log('Trying to remove user');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Users',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            };

            axios.post(configData.CONTROLLERURL + configData.REMOVEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove user data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        dispatch({
                            type: 'REMOVE_USER',
                            userid: id
                        });
                        handleCloseModal();
                        setResult('Success')
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            })
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeUser(id, setLoading, setResult)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='users'>
            <Title text='Users' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add User</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={() => viewUsersStatus(project.users)}>View Users Status</button>
                </div>
            </div>

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={exportPage}>Export</button>
                </div>
                <div className='buttonsection'>
                    <button className='button' onClick={importPage}>Import</button>
                </div>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Devices</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user, index) => {
                            return (
                                <tr key={'user' + index}>
                                    <td><div className='table_button' onClick={() => editButton(user)}>Edit</div></td>
                                    <td><div className='table_button' onClick={() => viewButton(user)}>View</div></td>
                                    <td><div>{user.id}</div></td>
                                    <td><div>{user.email}</div></td>
                                    <td>{user.devices && user.devices.map((device, index) => <div key={'device' + index}>{device.email}</div>)}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div >
    );
}

export default Users;