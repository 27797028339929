export const permissionResources = [
    'Archive',
    'Project',
    'Summary',
    'Cash Accounts',
    'Chart of Accounts',
    'Clients',
    'Collections',
    'Interaccount Transfers',
    'Inventory',
    'Journal Entries',
    'Payroll',
    'Purchase Invoices',
    'Reconciliations',
    'Reports',
    'Suppliers',
    'Transactions',
    'Sales Invoices',
    'Settings',
    'Users',
    'Statement of Account',
];

export function getPermission(projectuserid, users, userid, resource, option, archived) {
    if (archived) {
        return { code: 0, data: 'Project is archived' };
    }

    if (projectuserid === userid) {
        return { code: 1, data: 'ok' };
    }

    let user = users.find(user => user.id === userid);

    if (!user) {
        user = users.find(user =>
            user.devices && user.devices.some(device => device.id === userid)
        );
    }

    if (!user) {
        return { code: 0, data: 'User not found' };
    }

    const permissions = user.permissions[resource];
    if (!permissions) {
        return { code: 0, data: 'No permissions for resource' };
    }

    switch (option) {
        case 'open':
            return permissions.open ? { code: 1, data: 'ok' } : { code: 0, data: 'Permission denied' };
        case 'add':
            return permissions.add ? { code: 1, data: 'ok' } : { code: 0, data: 'Permission denied' };
        case 'update':
            return permissions.update ? { code: 1, data: 'ok' } : { code: 0, data: 'Permission denied' };
        case 'remove':
            return permissions.remove ? { code: 1, data: 'ok' } : { code: 0, data: 'Permission denied' };
        default:
            return { code: 0, data: 'Invalid permission option' };
    }
}

export function getHomePermission(projectuserid, users, userid) {
    let homePermissions = {};
    if (projectuserid === userid) {
        permissionResources.forEach(item => {
            homePermissions[item] = true;
        });
    }
    else {
        let user = users.find(user => user.id === userid) ||
            users.find(user => user.devices?.some(device => device.id === userid));

        if (user && user.permissions) {
            permissionResources.forEach(item => {
                homePermissions[item] = user.permissions[item]?.open ?? false;
            });
        }
    }

    return homePermissions;
}