import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import axios from 'axios';
import configData from './Config';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import Title from './Title.js';
import { numberToWords } from './Utils/textparse.js';

import Header from './Header.js';

import './CollectionsOverview.css';

const CHECKINTERVAL = 300000; // 300000 ms = 5 minutes

//20240525

function CollectionsOverview({ state, setState }) {
    const [{ projects }, dispatch] = useStateValue();

    const [collections, setCollections] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const printRef = useRef();

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const handleViewReceipt = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewReceipt collection={collection} />, modaltype: 'small', modaltitle: 'View Receipt' }));
    }

    const handleViewPayment = (collection) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewPayment collection={collection} />, modaltype: 'small', modaltitle: 'View Payment' }));
    }

    useEffect(() => {
        const fetchCollections = () => {
            const uniqueIds = projects.map(project => project.userid).filter((userId, index, array) => array.indexOf(userId) === index);
            console.log('Trying to get collections overview');
            setLoading(true);

            if (uniqueIds.length > 0) {
                setCollections([]);
                uniqueIds.forEach(userId => {
                    const data = {
                        projectuserid: userId
                    };

                    axios.post(configData.CONTROLLERURL + configData.GETCOLLECTIONSOVERVIEW, data, {
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json;charset=UTF-8",
                            "userid": state.user.userid,
                            "usertoken": state.user.usertoken
                        }
                    }).then((res) => {
                        console.log('Collections overview data received');
                        console.log(res.data);
                        if (res.data instanceof Array) {
                            setCollections(prevEntries => [...prevEntries, ...res.data]);
                        }
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                });
            }
        };

        fetchCollections();
        const interval = setInterval(fetchCollections, CHECKINTERVAL);
        return () => clearInterval(interval);
    }, [refresh]);

    function ViewReceipt({ collection }) {
        const [projectuserid, setProjectuserid] = useState('');
        const [projectid, setProjectid] = useState('');
        const [id, setId] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            setProjectuserid(collection.projectuserid)
            setProjectid(collection.projectid)
            setId(collection.id)
        }, [collection])

        const markCollection = (status) => {
            console.log('Trying to mark collection');

            setLoading(true)

            const data = {
                projectuserid: projectuserid,
                projectid: projectid,
                id: id,
                status: status
            };

            axios.post(configData.CONTROLLERURL + configData.MARKCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Mark collection data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        handleCloseModal();
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        const handleShare = () => {
            const projectName = projects.find(project => project.projectid === collection.projectid)?.name || 'Project';
            const collectorName = collection.collectorname ? collection.collectorname : '';
            const receiptText =
                "<100>RECEIPT\n" +
                "<100>COPY FOR CLIENT\n" +
                "<000>Receipt ID     : " + collection.id + "\n" +
                "<000>Project Name   : " + projectName + "\n" +
                "\n" +
                "<110>Client Information\n" +
                "<000>Client Name    : " + collection.clientname + "\n" +
                "\n" +
                "<110>Payment Details\n" +
                "<000>Description    : " + collection.description + "\n" +
                "<000>Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                "<000>Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencyname + "\n" +
                "\n" +
                "<110>Collector Information\n" +
                "<000>Collector      : " + collectorName + "\n" +
                "\n" +
                "<000>Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                "\n" +
                "<100>Thank you for your payment!\n" +
                "\n";

            if (navigator.share) {
                navigator.share({
                    title: 'Receipt',
                    text: receiptText,
                }).then(() => {
                    markCollection('received')
                }).catch((error) => {
                    console.error('Error sharing:', error)
                });

            } else {
                alert('Web Share API is not supported in your browser.');
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='collectionsoverview_printable'>
                        <pre className='collectionsoverview_printablepre'>
                            {
                                "RECEIPT\n" +
                                "**COPY FOR CLIENT**\n" +
                                "Receipt ID     : " + collection.id + "\n" +
                                "Project Name   : " + projects.find(project => project.projectid === collection.projectid)?.name + "\n" +
                                "\n" +
                                "Client Information\n" +
                                "Client Name    : " + collection.clientname + "\n" +
                                "\n" +
                                "Payment Details\n" +
                                "Description    : " + collection.description + "\n" +
                                "Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                                "Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencyname + "\n" +
                                "\n" +
                                "Collector Information\n" +
                                "Collector      : " + (collection.collectorname ? collection.collectorname : '') + "\n" +
                                "\n" +
                                "Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                                "\n" +
                                "Thank you for your payment!\n"
                            }
                        </pre>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='collectionsoverview_markbutton' onClick={() => markCollection('received')} disabled={loading}>Received - استلام</div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className="collectionsoverview_sharebutton" onClick={handleShare} disabled={loading}>Share</div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='collectionsoverview_closebutton' onClick={handleCloseModal}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function ViewPayment({ collection }) {
        const [projectuserid, setProjectuserid] = useState('');
        const [projectid, setProjectid] = useState('');
        const [id, setId] = useState('');

        const [loading, setLoading] = useState(false);
        const [result, setResult] = useState(null);

        useEffect(() => {
            setProjectuserid(collection.projectuserid)
            setProjectid(collection.projectid)
            setId(collection.id)
        }, [collection])

        const markCollection = (status) => {
            console.log('Trying to mark collection');

            setLoading(true)

            const data = {
                projectuserid: projectuserid,
                projectid: projectid,
                id: id,
                status: status
            };

            axios.post(configData.CONTROLLERURL + configData.MARKCOLLECTION, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Mark collection data received');
                console.log(res.data);
                if (res.data instanceof Object) {
                    if (res.data.code == 1) {
                        handleCloseModal();
                        setRefresh(!refresh)
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error')
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        };

        const handleShare = () => {
            const projectName = projects.find(project => project.projectid === collection.projectid)?.name || 'Project';
            const collectorName = collection.collectorname ? collection.collectorname : '';
            const receiptText =
                "<100>PAYMENT VOUCHER\n" +
                "<100>COPY FOR PAYER\n" +
                "<000>Receipt ID     : " + collection.id + "\n" +
                "<000>Project Name   : " + projectName + "\n" +
                "\n" +
                "<110>Beneficiary Information\n" +
                "<000>Beneficiary    : " + collection.suppliername + "\n" +
                "\n" +
                "<110>Payment Details\n" +
                "<000>Description    : " + collection.description + "\n" +
                "<000>Amount Paid    : " + collection.currencysymbol + " " + collection.amount + "\n" +
                "<000>Amount in Words: " + numberToWords(collection.amount) + " " + collection.currencyname + "\n" +
                "\n" +
                "<110>Payer Information\n" +
                "<000>Payer          : " + collectorName + "\n" +
                "\n" +
                "<000>Date           : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                "\n" +
                "<100>Thank you for your services!\n" +
                "\n";

            if (navigator.share) {
                navigator.share({
                    title: 'Payment',
                    text: receiptText,
                }).then(() => {
                    markCollection('received')
                }).catch((error) => {
                    console.error('Error sharing:', error)
                });
            } else {
                alert('Web Share API is not supported in your browser.');
            }
        };

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='collectionsoverview_printable'>
                        <pre className='collectionsoverview_printablepre'>
                            {
                                "PAYMENT VOUCHER\n" +
                                "**COPY FOR PAYER**\n" +
                                "Payment ID      : " + collection.id + "\n" +
                                "Project Name    : " + projects.find(project => project.projectid === collection.projectid)?.name + "\n" +
                                "\n" +
                                "Beneficiary Information\n" +
                                "Beneficiary     : " + collection.suppliername + "\n" +
                                "\n" +
                                "Payment Details\n" +
                                "Description     : " + collection.description + "\n" +
                                "Amount Paid     : " + collection.currencysymbol + " " + collection.amount + "\n" +
                                "Amount in Words : " + numberToWords(collection.amount) + " " + collection.currencyname + "\n" +
                                "\n" +
                                "Payer Information\n" +
                                "Payer           : " + (collection.collectorname ? collection.collectorname : '') + "\n" +
                                "\n" +
                                "Date            : " + moment().format('D/MMM/YYYY hh:mm:ss A') + "\n" +
                                "\n" +
                                "Thank you for your services!\n"
                            }
                        </pre>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='collectionsoverview_markbutton' onClick={() => markCollection('received')} disabled={loading}>Paid - تم الدفع</div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className="collectionsoverview_sharebutton" onClick={handleShare} disabled={loading}>Share</div>
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <div className='collectionsoverview_closebutton' onClick={handleCloseModal}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const receivedTotals = [];
    const paidTotals = [];
    collections.forEach(collection => {
        if (collection.type == 'receipt' && collection.status === 'received') {
            const currencyTotal = receivedTotals.find(total => total.currency === collection.currencysymbol);
            if (currencyTotal) {
                currencyTotal.amount += parseInt(collection.amount, 10);
            } else {
                receivedTotals.push({
                    currency: collection.currencysymbol,
                    amount: parseInt(collection.amount, 10)
                });
            }
        }
    });
    collections.forEach(collection => {
        if (collection.type == 'payment' && collection.status === 'received') {
            const currencyTotal = paidTotals.find(total => total.currency === collection.currencysymbol);
            if (currencyTotal) {
                currencyTotal.amount += parseInt(collection.amount, 10);
            } else {
                paidTotals.push({
                    currency: collection.currencysymbol,
                    amount: parseInt(collection.amount, 10)
                });
            }
        }
    });

    return (
        <div className='collectionsoverview'>
            <Header state={state} setState={setState} />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <ReactToPrint
                        trigger={() => (<button className="button">Print</button>)}
                        content={() => printRef.current}
                    />
                </div>
            </div>

            <div className='collectionsoverview_printable' ref={printRef}>
                <Title text='COLLECTIONS OVERVIEW' />
                <div className='collectionsoverview_tablecontainer'>
                    <table className='collectionsoverview_table'>
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>Due</th>
                                <th>Completed</th>
                                <th>ProjectName</th>
                                <th></th>
                                <th>Amount</th>
                                <th>Note</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && <tr><td colSpan={8}><div className='collectionsoverview_loading'>loading...</div></td></tr>}
                            {
                                collections.filter(collection => collection.status === 'pending').sort((a, b) => b.ts - a.ts).map((collection, index) => {
                                    if (collection.type == 'receipt') {
                                        return (
                                            <tr key={'receipt' + index} className='collectionsoverview_collection' onClick={() => handleViewReceipt(collection)}>
                                                <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                                <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                                <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                                <td><div>{projects.find(project => project.projectid === collection.projectid)?.name}</div></td>
                                                <td><div>{collection.clientname}</div></td>
                                                <td><div>{collection.currencysymbol} {collection.amount}</div></td>
                                                <td><div>{collection.note}</div></td>
                                                <td><div className='collectionsoverview_statuspending'>pending</div></td>
                                            </tr>
                                        )
                                    }
                                    if (collection.type == 'payment') {
                                        return (
                                            <tr key={'payment' + index} className='collectionsoverview_payment' onClick={() => handleViewPayment(collection)}>
                                                <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                                <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                                <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                                <td><div>{projects.find(project => project.projectid === collection.projectid)?.name}</div></td>
                                                <td><div>{collection.suppliername}</div></td>
                                                <td><div>{collection.currencysymbol} {collection.amount}</div></td>
                                                <td><div>{collection.note}</div></td>
                                                <td><div className='collectionsoverview_statuspending'>pending</div></td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                            {
                                collections.filter(collection => collection.status === 'received').sort((a, b) => b.completedts - a.completedts).map((collection, index) => {
                                    if (collection.type == 'receipt') {
                                        return (
                                            <tr key={'receipt' + index} className='collectionsoverview_collection' onClick={() => handleViewReceipt(collection)}>
                                                <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                                <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                                <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                                <td><div>{projects.find(project => project.projectid === collection.projectid)?.name}</div></td>
                                                <td><div>{collection.clientname}</div></td>
                                                <td><div>{collection.currencysymbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div></td>
                                                <td><div>{collection.note}</div></td>
                                                <td><div className='collectionsoverview_statusreceived'>received</div></td>
                                            </tr>
                                        )
                                    }
                                    if (collection.type == 'payment') {
                                        return (
                                            <tr key={'payment' + index} className='collectionsoverview_payment' onClick={() => handleViewPayment(collection)}>
                                                <td><div>{moment.unix(collection.ts).format('dddd, D MMM YYYY hh:mm:ss A')}</div></td>
                                                <td><div>{moment.unix(collection.atts).format('dddd')}</div></td>
                                                <td><div>{collection.completedts ? moment.unix(collection.completedts).format('dddd, D MMM YYYY hh:mm:ss A') : null}</div></td>
                                                <td><div>{projects.find(project => project.projectid === collection.projectid)?.name}</div></td>
                                                <td><div>{collection.suppliername}</div></td>
                                                <td><div>{collection.currencysymbol + ' ' + parseFloat(collection.amount).toLocaleString('en-US')}</div></td>
                                                <td><div>{collection.note}</div></td>
                                                <td><div className='collectionsoverview_statusreceived'>received</div></td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                        <tfoot>
                            {
                                receivedTotals.map(({ currency, amount }) => (
                                    <tr key={currency}>
                                        <td colSpan={5}></td>
                                        <td>
                                            <div>
                                                Total Received: {currency + ' ' + amount.toLocaleString('en-US')}
                                            </div>
                                        </td>
                                        <td colSpan={2}></td>
                                    </tr>
                                ))
                            }
                            {
                                paidTotals.map(({ currency, amount }) => (
                                    <tr key={currency}>
                                        <td colSpan={5}></td>
                                        <td>
                                            <div>
                                                Total Paid: {currency + ' ' + amount.toLocaleString('en-US')}
                                            </div>
                                        </td>
                                        <td colSpan={2}></td>
                                    </tr>
                                ))
                            }
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CollectionsOverview;