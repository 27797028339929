import React, { useState, useEffect, useRef } from 'react';
import select_magnifyingglass from '../Assets/select_magnifyingglass.png';
import select_closebutton from '../Assets/select_closebutton.png';

import './select.css';

const BOTTOM_THRESHOLD = 200;

const Select = ({ data, itemid, setItemid }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openUpwards, setOpenUpwards] = useState(false);

    const dropdownRef = useRef(null);
    const menusearchinputRef = useRef(null);

    const handleSelect = (itemId) => {
        setItemid(itemId);
        setShowDropdown(false);
    };

    const filteredData = [...data]
        .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        .filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            item.id !== itemid
        );

    useEffect(() => {
        if (showDropdown && menusearchinputRef.current) {
            menusearchinputRef.current.focus();

            const modalBodyElement = document.querySelector('.modal_body');
            if (modalBodyElement && dropdownRef.current) {
                const modalRect = modalBodyElement.getBoundingClientRect();
                const dropdownRect = dropdownRef.current.getBoundingClientRect();

                const spaceBelow = modalRect.bottom - dropdownRect.bottom;
                const spaceAbove = dropdownRect.top - modalRect.top;

                setOpenUpwards(spaceBelow < BOTTOM_THRESHOLD);
            }
        }
    }, [showDropdown]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modalElement = document.querySelector('.modal_printable');

            if (
                dropdownRef.current?.contains(event.target) ||
                (modalElement && event.target === modalElement && modalElement.scrollHeight > modalElement.clientHeight)
            ) {
                return;
            }
            setShowDropdown(false);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const clearSelection = () => {
        handleSelect(null);
        setShowDropdown(false);
        setItemid('');
    };

    const dropdown = () => {
        setShowDropdown(!showDropdown)
        setSearchTerm('')
    }

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown_selected">
                <input
                    type="text"
                    className='dropdown_selectedinput'
                    placeholder="Select"
                    value={itemid ? data.find(dataitem => dataitem.id === itemid)?.name : ''}
                    onClick={dropdown}
                    readOnly
                />
                {itemid
                    ? <img
                        src={select_closebutton}
                        alt="Close Button"
                        className="dropdown_selectedclearbutton"
                        style={{ width: '16px', height: '16px' }}
                        onClick={clearSelection}
                    />
                    : <img
                        src={select_magnifyingglass}
                        alt="Search"
                        className="dropdown_selectedclearbutton"
                        style={{ width: '16px', height: '16px' }}
                        onClick={dropdown}
                    />
                }
            </div>
            {showDropdown && (
                <div
                    className="dropdown_menu"
                    style={{
                        top: openUpwards ? 'auto' : '100%',
                        bottom: openUpwards ? '100%' : 'auto',
                    }}
                >
                    <input
                        type="text"
                        className="dropdown_menusearchinput"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        ref={menusearchinputRef}
                    />
                    <ul className='dropdown_menulist'>
                        {filteredData.map((item, index) => (
                            <li
                                key={'item' + index}
                                className='dropdown_menulistitem'
                                onClick={() => handleSelect(item.id)}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Select;