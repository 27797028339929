import React, { useState, useEffect } from 'react';

import Title from './Title';

import settings_currency from './Assets/settings_currency.png';
import settings_startdate from './Assets/settings_startdate.png';
import settings_exchangerates from './Assets/settings_exchangerates.png';
import settings_projectdetails from './Assets/settings_projectdetails.png';
import settings_subaccounts from './Assets/settings_subaccounts.png';

import Currency from './Settings/Currency';
import StartDate from './Settings/StartDate';
import ExchangeRates from './Settings/ExchangeRates';
import ProjectDetails from './Settings/ProjectDetails';
import Subaccounts from './Settings/Subaccounts';

import './Settings.css';

function Settings({ state, setState }) {

    const [option, setOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='settings'>
            <Title text='Settings' />

            <div className='settings_buttonscontainer'>

                <div className='settings_button' onClick={() => setOption(<Currency state={state} setState={setState} />)}>
                    <img src={settings_currency} alt="Currency" />
                    <span className='settings_buttontext'>Currency</span>
                </div>

                <div className='settings_button' onClick={() => setOption(<StartDate state={state} setState={setState} />)}>
                    <img src={settings_startdate} alt="Start Date" />
                    <span className='settings_buttontext'>Start Date</span>
                </div>

                <div className='settings_button' onClick={() => setOption(<ExchangeRates state={state} setState={setState} />)}>
                    <img src={settings_exchangerates} alt="Exchange Rates" />
                    <span className='settings_buttontext'>Exchange Rates</span>
                </div>

                <div className='settings_button' onClick={() => setOption(<ProjectDetails state={state} setState={setState} />)}>
                    <img src={settings_projectdetails} alt="Project Details" />
                    <span className='settings_buttontext'>Project Details</span>
                </div>

                <div className='settings_button' onClick={() => setOption(<Subaccounts state={state} setState={setState} />)}>
                    <img src={settings_subaccounts} alt="Subaccounts" />
                    <span className='settings_buttontext'>Subaccounts</span>
                </div>

            </div>

            {option}

        </div>
    );
}

export default Settings;