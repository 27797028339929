import React, { useState, useEffect } from 'react';
import { useStateValue } from '../StateProvider';
import moment from 'moment';
import axios from 'axios';
import configData from '../Config.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Title from '../Title';
import Select from '../Components/select.js';

import { getPermission } from '../Utils/permissions.js';
import '../Settings.css';

function ExchangeRates({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [exchangerates, setExchangerates] = useState([]);

    useEffect(() => {
        const sortedExchangeRates = [...project.exchangerates].sort((a, b) => b.ts - a.ts);
        setExchangerates(sortedExchangeRates);
    }, [project.exchangerates]);

    const cancelButton = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddExchangeRate />, modaltype: 'large', modaltitle: 'Add Exchange Rate' }));
    }

    const editButton = (exchangerate) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditExchangeRate exchangerate={exchangerate} />, modaltype: 'large', modaltitle: 'Edit Exchange Rate' }));
    }

    const removeButton = (exchangerate) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <RemoveExchangeRate exchangerate={exchangerate} />, modaltype: 'small', modaltitle: 'Remove Exchange Rate' }));
    }

    function AddExchangeRate() {
        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [fromcurrencyid, setFromcurrencyid] = useState('');
        const [toamount, setToamount] = useState(0);
        const [tocurrencyid, setTocurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const addExchangeRate = (timestamp, fromcurrencyid, toamount, tocurrencyid) => {
            console.log('Trying to add exchange rate');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Settings',
                'add',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!fromcurrencyid || isNaN(parseFloat(toamount)) || parseFloat(toamount) <= 0) {
                setResult('Missing data');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                ts: timestamp,
                fromcurrencyid: fromcurrencyid,
                toamount: toamount,
                tocurrencyid: tocurrencyid
            }

            axios.post(configData.CONTROLLERURL + configData.ADDEXCHANGERATE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Add exchange rate data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        data.id = res.data.id;
                        dispatch({
                            type: 'ADD_EXCHANGERATE',
                            exchangerate: data
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                From
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>1</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={fromcurrencyid} setItemid={setFromcurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                To
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Rate"
                                value={toamount}
                                onChange={(e) => setToamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={tocurrencyid} setItemid={setTocurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => addExchangeRate(timestamp, fromcurrencyid, toamount, tocurrencyid)} disabled={loading}>{loading ? 'Loading...' : 'Add'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function EditExchangeRate({ exchangerate }) {
        const [id, setId] = useState('');

        const [selecteddate, setSelecteddate] = useState(new Date());
        const [timestamp, setTimestamp] = useState(0);

        const [fromcurrencyid, setFromcurrencyid] = useState('');
        const [toamount, setToamount] = useState(0);
        const [tocurrencyid, setTocurrencyid] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(exchangerate.id);
            setSelecteddate(new Date(exchangerate.ts * 1000));
            setFromcurrencyid(exchangerate.fromcurrencyid);
            setToamount(exchangerate.toamount);
            setTocurrencyid(exchangerate.tocurrencyid);
        }, [exchangerate]);

        useEffect(() => {
            const unixTimestamp = Math.floor(selecteddate.getTime() / 1000);
            setTimestamp(unixTimestamp);
        }, [selecteddate]);

        const updateExchangeRate = (id, timestamp, fromcurrencyid, toamount, tocurrencyid) => {
            console.log('Trying to update exchange rate');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Settings',
                'update',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            if (!fromcurrencyid || isNaN(parseFloat(toamount)) || parseFloat(toamount) <= 0) {
                setResult('Missing data');
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id,
                ts: timestamp,
                fromcurrencyid: fromcurrencyid,
                toamount: toamount,
                tocurrencyid: tocurrencyid
            }

            axios.post(configData.CONTROLLERURL + configData.UPDATEEXCHANGERATE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update exchange rate data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'UPDATE_EXCHANGERATE',
                            exchangerate: data
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>Date</div>
                        </div>
                        <div className='modal_rowsection'>
                            <DatePicker
                                className='modal_datepicker'
                                selected={selecteddate}
                                onChange={(date) => setSelecteddate(date)}
                            />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                From
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>1</div>
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={fromcurrencyid} setItemid={setFromcurrencyid} />
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <div className='modal_label'>
                                To
                            </div>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <input
                                className='modal_input'
                                type="number"
                                placeholder="Rate"
                                value={toamount}
                                onChange={(e) => setToamount(e.target.value)}
                            />
                        </div>
                        <div className='modal_rowsection'>
                            <Select data={project.currencies} itemid={tocurrencyid} setItemid={setTocurrencyid} />
                        </div>
                    </div>
                </div>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => updateExchangeRate(id, timestamp, fromcurrencyid, toamount, tocurrencyid)} disabled={loading}>{loading ? 'Loading...' : 'Update'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeButton(exchangerate)}>Remove</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RemoveExchangeRate({ exchangerate }) {
        const [id, setId] = useState('');

        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setId(exchangerate.id);
        }, [exchangerate]);

        const removeExchangeRate = (id) => {
            console.log('Trying to remove exchange rate');

            setResult('');

            const hasPermission = getPermission(
                project.projectuserid,
                project.users,
                state.user.userid,
                'Settings',
                'remove',
                project.archived
            );
            if (hasPermission.code == 0) {
                setResult(hasPermission.data);
                return;
            }

            setLoading(true);

            const data = {
                projectuserid: project.projectuserid,
                projectid: project.projectid,
                id: id
            }

            axios.post(configData.CONTROLLERURL + configData.REMOVEEXCHANGERATE, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Remove exchange rate data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        dispatch({
                            type: 'REMOVE_EXCHANGERATE',
                            exchangerateid: id
                        });
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data)
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            }).finally(() => {
                setLoading(false);
            });
        }

        return (
            <div className='modal_body'>
                <div className='modal_actions'>
                    {result && <div className='modal_result'>{result}</div>}
                    <div className='modal_buttons'>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={() => removeExchangeRate(id)} disabled={loading}>{loading ? 'Loading...' : 'Remove'}</button>
                        </div>
                        <div className='modal_buttoncontainer'>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='settings_exchangerates'>
            <Title text='Exchange Rates' />

            <div className='buttonscontainer'>
                <button className='button' onClick={addButton}>New Exchange Rate</button>
            </div>

            <table className='table'>
                <thead><tr><th></th><th>Date</th><th>Currency</th><th>Rate</th></tr></thead>
                <tbody>
                    {
                        exchangerates.map((exchangerate, index) => {
                            const fromCurrency = project.currencies.find(item => item.id === exchangerate.fromcurrencyid, 10)?.symbol || 'Currency not found';
                            const toCurrency = project.currencies.find(item => item.id === exchangerate.tocurrencyid, 10)?.symbol || 'Currency not found';
                            const toAmount = parseFloat(exchangerate.toamount).toLocaleString('en-US')

                            return (
                                <tr key={'exchangerate' + index}>
                                    <td><div className='table_button' onClick={() => editButton(exchangerate)}>Edit</div></td>
                                    <td><div>{moment.unix(exchangerate.ts).format('D/MMM/YYYY')}</div></td>
                                    <td><div>{fromCurrency} 1</div></td>
                                    <td><div>{toCurrency} {toAmount}</div></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ExchangeRates;