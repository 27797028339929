import React, { useState, useEffect, useRef } from 'react';
import { useStateValue } from './StateProvider.js';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import configData from './Config';

import { getPermission } from './Utils/permissions.js';
import Title from './Title.js';
import StatementAccount from './StatementAccount.js';
import { getSupplierAccounts } from './Utils/suppliers.js';
import { journalentryremovalprotection, transactionsremovalprotection, purchaseinvoicesremovalprotection, contractsremovalprotection } from './Utils/removeprotection.js';

import './Payroll.css';

function Payroll({ state, setState }) {
    const [{ project }, dispatch] = useStateValue();

    const [filteredsuppliers, setFilteredsuppliers] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleCloseModal = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const addButton = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <AddEmployee />, modaltype: 'large', modaltitle: 'Add Employee' }));
    }

    function AddEmployee() {
        
    }

    return (
        <div className='payroll'>
            <Title text='Payroll' />

            <div className='buttonscontainer'>
                <div className='buttonsection'>
                    <button className='button' onClick={addButton}>Add Employees</button>
                </div>
                <div className='buttonsection'>
                    <input
                        className='input'
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

        </div>
    );
}

export default Payroll;