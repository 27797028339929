import React, { useState, useEffect } from 'react';
import moment from 'moment';

import axios from 'axios';
import configData from '../Config';

function AdminUsers({ state, setState }) {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    const [refresh, setRefresh] = useState(false);
    const [result, setResult] = useState(null);

    const cancelButton = () => {
        setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null, modaltitle: null }));
    }

    const editButton = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditUser user={user} />, modaltype: 'small', modaltitle: 'Edit User' }));
    }

    const viewButton = (user) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <ViewUser userid={user.id} />, modaltype: 'small', modaltitle: 'View User' }));
    }

    useEffect(() => {
        console.log('Trying to get users');

        const timeout = setTimeout(() => {
            const data = {
                search: search,
                offset: 0,
                limit: 10
            };

            axios.post(configData.CONTROLLERURL + configData.ADMINGETUSERS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Get users data received');
                console.log(res.data);
                if (res.data instanceof Array) {
                    setUsers(res.data)
                }
            }).catch((error) => {
                console.error('Error fetching users:', error);
            });
        }, 500);

        return () => clearTimeout(timeout);
    }, [search, refresh]);

    function EditUser({ user }) {
        const [id, setId] = useState('');
        const [email, setEmail] = useState('');
        const [status, setStatus] = useState('');
        const [username, setUsername] = useState('');
        const [newpassword, setNewpassword] = useState('');

        useEffect(() => {
            setId(user.id);
            setEmail(user.email);
            setStatus(user.status);
            setUsername(user.name);
        }, [user]);

        const updateUser = (id, email, username, status) => {
            console.log('Trying to update user');

            setResult(null);

            const data = {
                id: id,
                email: email,
                username: username,
                status: status
            }

            axios.post(configData.CONTROLLERURL + configData.ADMINUPDATEUSER, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update user data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        setRefresh(!refresh)
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch((err) => {
                setResult(err.response?.data?.message || err.message || 'Network error');
            })
        }

        const updatePassword = (id, newpassword) => {
            console.log('Trying to update user password');

            setResult(null);

            const data = {
                id: id,
                newpassword: newpassword,
            }

            axios.post(configData.CONTROLLERURL + configData.ADMINUPDATEUSERPASSWORD, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                }
            }).then((res) => {
                console.log('Update user password data received')
                console.log(res.data)
                if (res.data instanceof Object) {
                    if (res.data.code === 1) {
                        cancelButton();
                    }
                    else {
                        setResult(res.data.data);
                    }
                }
                else {
                    setResult('Error');
                }
            }).catch(() => {
                setResult('Error');
            })
        }

        return (
            <div className='modal'>
                <div className='modal_content'>
                    <div className='modal_scrollable'>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    placeholder="Name"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <select className='modal_select' value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">Select status</option>
                                    <option value='A'>Active</option>
                                    <option value='P'>Pending</option>
                                </select>
                            </div>
                        </div>
                        <div className='modal_row'>
                            <div className='modal_rowsection'>
                                <input
                                    className='modal_input'
                                    type="text"
                                    value={newpassword}
                                    onChange={(e) => setNewpassword(e.target.value)}
                                />
                            </div>
                            <div className='modal_rowsection'>
                                <button className="modal_button" onClick={() => updatePassword(id, newpassword)}>Change Password</button>
                            </div>
                        </div>
                        <div className='modal_result'>{result && result}</div>
                        <div className="modal_buttonscontainer">
                            <button className="modal_button" onClick={() => updateUser(id, email, username, status)}>Update</button>
                            <button className="modal_button" onClick={cancelButton}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ViewUser({ userid }) {
        const [projects, setProjects] = useState([]);
        const [archivemode, setArchivemode] = useState(false);

        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setLoading(true);
            if (archivemode == false) {
                const data = {
                    userid: userid
                }

                axios.post(configData.CONTROLLERURL + configData.ADMINGETUSERPROJECTS, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then((res) => {
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setProjects(res.data)
                    }
                }).catch((err) => {
                    setResult(err.response?.data?.message || err.message || 'Network error');
                }).finally(() => {
                    setLoading(false)
                })
            }
            if (archivemode == true) {
                const data = {
                    userid: userid
                }

                axios.post(configData.CONTROLLERURL + configData.ADMINGETUSERARCHIVES, data, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "userid": state.user.userid,
                        "usertoken": state.user.usertoken
                    }
                }).then((res) => {
                    console.log(res.data)
                    if (res.data instanceof Array) {
                        setProjects(res.data)
                    }
                }).catch((err) => {
                    setResult(err.response?.data?.message || err.message || 'Network error');
                }).finally(() => {
                    setLoading(false)
                })
            }
        }, [archivemode]);

        return (
            <div className='modal_body'>
                <div className='modal_printable'>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            <button className="modal_button" onClick={() => setArchivemode(!archivemode)}>Archive</button>
                        </div>
                    </div>
                    <div className='modal_row'>
                        <div className='modal_rowsection'>
                            {
                                projects.map((project, index) => {
                                    const groupedPermissions = {
                                        'Full Permissions': [],
                                        'Open-Only Permissions': [],
                                        'Add-Only Permissions': [],
                                        'Update-Only Permissions': [],
                                        'Remove-Only Permissions': [],
                                        'No Permissions': [],
                                        'Mixed Permissions': []
                                    };

                                    Object.entries(project.permissions).forEach(([key, value]) => {
                                        switch (value) {
                                            case '1111':
                                                groupedPermissions['Full Permissions'].push(key);
                                                break;
                                            case '1000':
                                                groupedPermissions['Open-Only Permissions'].push(key);
                                                break;
                                            case '0100':
                                                groupedPermissions['Add-Only Permissions'].push(key);
                                                break;
                                            case '0010':
                                                groupedPermissions['Update-Only Permissions'].push(key);
                                                break;
                                            case '0001':
                                                groupedPermissions['Remove-Only Permissions'].push(key);
                                                break;
                                            case '0000':
                                                groupedPermissions['No Permissions'].push(key);
                                                break;
                                            default:
                                                groupedPermissions['Mixed Permissions'].push(key);
                                                break;
                                        }
                                    });

                                    return (
                                        <div key={'project' + index}>
                                            <div>{project.name}</div>
                                            <div style={{ fontSize: 8 }}>
                                                {Object.entries(groupedPermissions).map(([group, permissions]) =>
                                                    permissions.length > 0 && (
                                                        <div key={group}>
                                                            <strong>{group} ({permissions.length} resources):</strong>
                                                            <ul>
                                                                {permissions.map((perm, idx) => (
                                                                    <li key={idx}>{perm}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <div className='modal_body'>
            <div className='modal_printable'>
                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <input
                            className='modal_input'
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>

                <div className='modal_row'>
                    <div className='modal_rowsection'>
                        <table className='modal_table'>
                            <thead><tr><th></th><th></th><th>Id</th><th>Email</th><th>Username</th><th>Last login</th><th>Status</th></tr></thead>
                            <tbody>
                                {
                                    users.map((user, index) => {
                                        let parsed = JSON.parse(user.token);

                                        return (
                                            <tr key={'user' + index}>
                                                <td><div className='table_button' onClick={() => editButton(user)}>Edit</div></td>
                                                <td><div className='table_button' onClick={() => viewButton(user)}>View</div></td>
                                                <td><div>{user.id}</div></td>
                                                <td><div>{user.email}</div></td>
                                                <td><div>{user.name}</div></td>
                                                <td><div>{moment.unix(parsed.ts).format('D MMMM YYYY hh:mm:ss A')}</div></td>
                                                <td><div>{user.status}</div></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminUsers;